import React, { useEffect, useState } from "react";
import Header from "./common/header";
import Footer from "./common/footer";
import SubmitForm from "./common/submit_form";
import API from "../api";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";

function PoliceDetail() {
  const { id } = useParams();
  const [police, setPolice] = useState({ title: "", description: "" });
  const [polices, setPolices] = useState([]);
  const [data, setData] = useState("");
  const [logo, setLogo] = useState("");

  const getCompanyinfo = async () => {
    API.getAPIData("/api/company/get_company_information.php").then((res) => {
      if (res.success) {
        const data = res.data;

        setData(data);
        setLogo(data.url_logo);
      }
    });
  };

  const getPolice = async () => {
    await API.getAPIData(
      `/api/company/get_police_data_detail.php?id=${id}`
    ).then((res) => {
      if (res.success) {
        setPolice(res.data);
        setPolices(res.list_data);
      } else {
        setPolice({ title: "", description: "" });
        setPolices([]);
      }
    });
  };

  useEffect(() => {
    getCompanyinfo();
    getPolice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <Header backgroundColor={data.color_web} imgLogo={logo} data={data} />
      <div className="container police">
        <Row className="p-3">
          <Col className="col-lg-8 col-12">
            <div>
              <h2 className="heading--4">{police.title}</h2>
              <hr />
              <div>{parse(police.description)}</div>
            </div>
          </Col>
          <Col className="col-lg-4 col-12">
            {polices.map((value, index) => {
              return (
                <Row className="wrap" key={`police-${index}`}>
                  <Col className="col-lg-5 col-12">
                    <a
                      className="image-list-police"
                      href={`/police/detail/${value.id}`}
                    >
                      <img
                        src={"/api/image/" + value.url_image}
                        alt={`police-${index}`}
                      />
                    </a>
                  </Col>
                  <Col className="col-lg-7 col-12 padding-0">
                    <a className="title" href={`/police/detail/${value.id}`}>
                      {value.title}
                    </a>
                    <div className="text-4-line description">
                      {value.sub_title}
                    </div>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </div>

      <SubmitForm backgroundColor={data.color_web} title="REACH US" />

      <Footer backgroundColor={data.color_web} imgLogo={logo} data={data} />
    </section>
  );
}

export default PoliceDetail;
