import React, { useEffect, useState } from "react";
import NavBar from "../common/navbar";
import Header from "../common/header";
import API from "../../../api";
import Confirm from "../common/confirm";
import { toast } from "react-toastify";
import { caculateOffSet, caculatePage } from "../../helper";
import Paginator from "../common/Paginato";
import OrderDetail from "./order_detail";

function Orders() {
  const [products, setProducts] = useState([]);
  const [idApprove, setidApprove] = useState(0);
  const [idEdit, setidEdit] = useState(0);
  const [page, setPage] = useState({
    page: 1,
    size: 10,
    totalPages: 10,
  });

  useEffect(() => {
    getOrders(1, 10);
  }, []);

  const getOrders = async (newPage, newSize) => {
    await API.getAPIData(
      `/api/order/get_order_list.php?offset=${caculateOffSet(
        newPage,
        newSize
      )}&size=${newSize}`
    ).then((res) => {
      if (res.success) {
        setProducts(res.data);
        setPage({
          page: newPage,
          size: 10,
          totalPages: caculatePage(res.total, newSize),
        });
      } else {
        setProducts([]);
      }
    });
  };

  const onCloseApprove = () => {
    setidApprove(0);
  };

  const onApprove = (idDetail) => {
    setidApprove(idDetail);
  };

  const onPageChange = (newPage) => {
    getOrders(newPage, page.size);
  };

  const submitApprove = async () => {
    await API.getAPIData(`/api/order/approve.php?id=${idApprove}`).then(
      (res) => {
        if (res.success) {
          getOrders(page.page, page.size);
        } else {
          toast.error(res.message);
        }
      }
    );
    onCloseApprove();
  };

  const onEdit = (id) => {
    setidEdit(id);
  };

  const onCloseDetail = () => {
    setidEdit(0);
  };

  return (
    <section className="dashboard-admin">
      <OrderDetail
        visible={!!idEdit}
        onClose={onCloseDetail}
        id={idEdit}
        onSubmit={onCloseDetail}
      />
      <NavBar />
      <Confirm
        visible={!!idApprove}
        header={"Approve Order"}
        title={"Are you sure you want to approve order?"}
        onConfirm={submitApprove}
        onClose={onCloseApprove}
      />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Orders" />
        <div className="container-fluid py-4">
          {/* <div className="d-flex justify-content-end">
            <button
              style={{ border: "0px" }}
              className="justify-content-end badge badge-sm bg-gradient-primary p-2"
              onClick={() => setOpenDetail(true)}
            >
              <span className="p-2">Add new</span>
            </button>
          </div> */}
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Order code
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Customer Name
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Customer Phone
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Customer Email
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Status
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Thanh toán
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Đã thanh toán
                          </th>
                          <th
                            className="text-uppercase text-xxs font-weight-bold text-center"
                            style={{ width: "70px" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="align-middle">
                                <span>{item.order_code}</span>
                              </td>
                              <td className="align-middle">
                                <span>{item.full_name}</span>
                              </td>
                              <td>
                                <span>{item.phone}</span>
                              </td>
                              <td className="align-middle">
                                <span>{item.email}</span>
                              </td>
                              <td className="align-middle">
                                {item.is_process === "0" ? (
                                  <span className="text-processing">
                                    Processing
                                  </span>
                                ) : (
                                  <span className="text-processed">
                                    Processed
                                  </span>
                                )}
                              </td>
                              <td className="align-middle">
                                {item.payment_menthod}
                              </td>
                              <td className="align-middle">
                                {item.is_payment === "0" ? (
                                  <span className="text-processing">No</span>
                                ) : (
                                  <span className="text-processed">OK</span>
                                )}
                              </td>
                              <td className="align-middle justify-content-space-between">
                                <button
                                  className={
                                    item.is_process === "1"
                                      ? "btn-edit-approve back-ground-dimgray mx-1"
                                      : "btn-edit-approve mx-1"
                                  }
                                  onClick={() => onApprove(item.id)}
                                  disabled={item.is_process === "1"}
                                >
                                  Approve
                                </button>

                                <button
                                  className="btn-edit-list "
                                  onClick={() => onEdit(item.id)}
                                >
                                  Detail
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {caculatePage(page.totalPages, page.size) > 0 && (
                      <Paginator
                        {...page}
                        onPageChange={(newpage) => onPageChange(newpage)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
}

export default Orders;
