import React from "react";
import * as Icon from "react-feather";

export default function ReviewStar({
  star,
  onClickStar,
  onMouseHover,
  addkey,
}) {
  const starList = [
    { id: 1, lable: "Rất tệ" },
    { id: 2, lable: "Tệ" },
    { id: 3, lable: "Tạm ổn" },
    { id: 4, lable: "Tốt" },
    { id: 5, lable: "Rất tốt" },
  ];

  return (
    <div className="product-review-star" key={addkey}>
      {starList.map((starItem) => {
        return (
          <div key={`${starItem.id}-${addkey}`}>
            <Icon.Star
              size={50}
              onClick={() => onClickStar(starItem.id)}
              strokeWidth={0.5}
              color="#ffae00"
              fill={star >= starItem.id ? "#ffae00" : "#ffffff"}
              onMouseEnter={() => onMouseHover(starItem.id)}
            />
            <div className="name-star">{starItem.lable}</div>
          </div>
        );
      })}
    </div>
  );
}
