import React, { useState } from "react";
import emailjs from "@emailjs/browser";

function SubmitForm({ backgroundColor, title, subTitle }) {
  const [phoneErrom, setPhoneError] = useState("");

  function phoneValidation(numPhone) {
    const regex = /^(84|0[3|5|7|8|9])+([0-9]{8})\b/i;
    return !(!numPhone || regex.test(numPhone) === false);
  }

  function sendEmail(e) {
    e.preventDefault();
    const phoneNumber = e.target[3].value;

    if (phoneValidation(phoneNumber)) {
      setPhoneError("");
      emailjs.sendForm(
        "service_zbwixfg",
        "template_o0k61cw",
        e.target,
        "vNsBPz204BM7DA6K9"
      );
      e.target.reset();
      alert(
        "Cảm ơn bạn đã quan tâm, Chúng tôi sẽ phản hồi bạn trong thời gian sớm nhất!"
      );
    } else {
      setPhoneError("The phone number is not in the correct format!");
    }
  }
  return (
    <>
      <article className="wp-block-luna-m09-contact break-out m09--red-full m09--red form-container">
        <div className="container">
          <h2 className="m09__heading uppercase">
            <span className="m09__label heading--6">{title}</span>
            <span className="m09__heading-text heading--2">{subTitle}</span>
          </h2>
        </div>
      </article>

      <article
        className="wp-block-luna-m09-contact break-out m09--red-full m09--red form-container"
        style={{ backgroundColor: backgroundColor, padding: "1rem" }}
      >
        <div className="container">
          <form onSubmit={sendEmail}>
            <div className="gf_browser_chrome gform_legacy_markup_wrapper padding-description">
              <div className="gform_body gform-body">
                <ul
                  id="gform_fields_2"
                  className="gform_fields top_label form_sublabel_below description_below"
                >
                  <li
                    id="field_2_1"
                    className="gfield gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible gfield_text"
                  >
                    <label className="gfield_label" htmlFor="input_2_1">
                      First Name
                      <span className="gfield_required">
                        <span className="gfield_required gfield_required_asterisk">
                          *
                        </span>
                      </span>
                    </label>
                    <div className="ginput_container ginput_container_text">
                      <input
                        type="text"
                        className="medium"
                        name="first_name"
                        required
                      />
                    </div>
                  </li>
                  <li
                    id="field_2_11"
                    className="gfield gfield--width-full gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible gfield_text"
                  >
                    <label className="gfield_label" htmlFor="input_2_11">
                      Last Name
                      <span className="gfield_required">*</span>
                    </label>
                    <div className="ginput_container ginput_container_text">
                      <input
                        type="text"
                        className="medium"
                        name="last_name"
                        required
                      />
                    </div>
                  </li>
                  <li
                    id="field_2_4"
                    className="gfield gfield--width-full gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible gfield_email"
                  >
                    <label className="gfield_label" htmlFor="input_2_4">
                      Work email
                      <span className="gfield_required">*</span>
                    </label>
                    <div className="ginput_container ginput_container_email">
                      <input
                        type="email"
                        className="medium"
                        name="email"
                        required
                      />
                    </div>
                  </li>
                  <li
                    id="field_2_3"
                    className="gfield field_sublabel_below field_description_below gfield_visibility_visible gfield_text"
                  >
                    <label className="gfield_label" htmlFor="input_2_3">
                      Phone
                    </label>
                    <div className="ginput_container ginput_container_text">
                      <input
                        type="text"
                        className="medium"
                        name="phone"
                        required
                      />
                    </div>
                    <div
                      className="gfield_description"
                      id="gfield_description_2_15"
                    >
                      {phoneErrom}
                    </div>
                  </li>
                  <li
                    id="field_2_15"
                    className="gfield gfield--width-full gfield_html gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible gfield_text"
                  >
                    <label className="gfield_label" htmlFor="input_2_15">
                      Email subject
                      <span className="gfield_required">*</span>
                    </label>
                    <div className="ginput_container ginput_container_text">
                      <input
                        type="text"
                        className="large"
                        name="subject"
                        required
                      />
                    </div>
                    <div
                      className="gfield_description"
                      id="gfield_description_2_15"
                    >
                      Please give a brief description of how we can help.
                    </div>
                  </li>
                  <li
                    id="field_2_6"
                    className="gfield gfield--width-full gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible gfield_textarea"
                  >
                    <label className="gfield_label" htmlFor="input_2_6">
                      Your message
                      <span className="gfield_required">*</span>
                    </label>
                    <div className="ginput_container ginput_container_textarea">
                      <textarea
                        className="textarea large"
                        rows="10"
                        cols="50"
                        name="message"
                        required
                      ></textarea>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="gform_footer top_label">
                <button
                  type="submit"
                  className="button gform_button"
                  id="gform_submit_button_2"
                >
                  Send message
                  <svg
                    aria-hidden="true"
                    className="svg-icon svg-icon--icon_arrow"
                    role="img"
                  >
                    <use href="#sprite-icon_arrow"></use>
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
      </article>
    </>
  );
}

export default SubmitForm;
