import React, { useEffect, useState } from "react";
import Header from "./common/header";
import Footer from "./common/footer";
import SubmitForm from "./common/submit_form";
import API from "../api";
import successImg from "../assets/images/success.png";
import { isNumeric, numberWithComma } from "./helper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PopupNotify from "./common/popup_notify";

function CompletedOrder() {
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [products, setProducts] = useState([]);
  const [data, setData] = useState("");
  const [logo, setLogo] = useState("");
  const [otp, setOtp] = useState("");
  const [showNotify, setShowNotify] = useState(false);

  const getCompanyinfo = async () => {
    API.getAPIData("/api/company/get_company_information.php").then((res) => {
      if (res.success) {
        const data = res.data;

        setData(data);
        setLogo(data.url_logo);
      }
    });
  };

  const getOrderComplete = async () => {
    await API.getAPIData(`/api/order/get_order_completed.php`).then((res) => {
      if (res.success) {
        setOrder(res.data);
        setProducts(res.items);
      } else {
        navigate("/product");
      }
    });
  };

  const onSuccess = () => {
    navigate("/product");
  };

  useEffect(() => {
    getCompanyinfo();
    getOrderComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheckOtp = () => {
    if (otp.length !== 4) {
      toast.error("Mã OTP có 4 ký tự số.");
      return;
    }

    if (!isNumeric(otp)) {
      toast.error("Mã OTP phải là số.");
      return;
    }
    setShowNotify(true);
  };

  return (
    <section>
      <Header backgroundColor={data.color_web} imgLogo={logo} data={data} />
      <div className="payment-wrap">
        <div className="d-flex align-items-center justify-content-center py-2">
          <img src={successImg} alt="success" className="img-success" />
          <span className="mx-2">Đơn hàng của bạn đã đặt thành công</span>
        </div>
        <div className="justify-content-center mt-2">
          <div className="fs-3 fw-semibold text-center">Thông tin đơn hàng</div>
          {order && (
            <div className="show-infor">
              <table className="my-2">
                <tbody>
                  <tr>
                    <th className="fw-medium" style={{ width: "100px" }}>
                      Họ và tên:
                    </th>
                    <th>
                      <span className="fw-light">{order.full_name}</span>
                    </th>
                  </tr>
                  <tr>
                    <th className="fw-medium">Điện thoại:</th>
                    <th>
                      <span className="fw-light">{order.phone}</span>
                    </th>
                  </tr>
                  <tr>
                    <th className="fw-medium">Email:</th>
                    <th>
                      <span className="fw-light">{order.email}</span>
                    </th>
                  </tr>
                  <tr>
                    <th className="fw-medium">Địa chỉ:</th>
                    <th>
                      <span className="fw-light">{order.address}</span>
                    </th>
                  </tr>
                  <tr>
                    <th className="fw-medium">Ghi chú:</th>
                    <th>
                      <span className="fw-light">
                        {order.note} MB - Ngân hàng TMCP Quân đội, MB - Ngân
                        hàng TMCP Quân đội
                      </span>
                    </th>
                  </tr>
                </tbody>
              </table>
              <div className="mb-2 tab-info-bank">
                <div className="fw-semibold">
                  CÔNG TY TNHH TIẾP THỊ ỨNG DỤNG THÔNG THÁI
                </div>
                <div className="text-center border p-2 rounded">
                  <div>6913688888</div>
                  <div>MB - Ngân hàng TMCP Quân đội</div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mt-3">
          <div className="fs-3 fw-semibold text-center">Xác Nhận OTP</div>
          <div className="d-flex justify-content-center p-2">
            <input
              type="text"
              placeholder="Nhập mã OTP"
              className="border ps-3"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              maxLength={4}
            />
            <button className="border" onClick={() => onCheckOtp()}>
              Xác nhận
            </button>
          </div>
        </div>
        {products.map((value, index) => {
          return (
            <div className="d-flex payment-wrap-items" key={index}>
              <div style={{ width: "80px" }}>
                <img src={"/api/image/" + value.url_image} alt="" />
              </div>
              <div className="payment-product-name">
                <div>{value.product_name}</div>
                <div>
                  Quantity: <span>{numberWithComma(value.quantity)}</span>
                </div>
              </div>
              <div className="payment-price">
                {numberWithComma(value.price)}
              </div>
            </div>
          );
        })}
      </div>

      <SubmitForm backgroundColor={data.color_web} title="REACH US" />
      <PopupNotify
        visible={showNotify}
        notify={{ isSuccess: true, message: "Bạn đã xác nhận OTP thành công." }}
        onSuccess={onSuccess}
      />

      <Footer />
    </section>
  );
}

export default CompletedOrder;
