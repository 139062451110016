import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import API from "../../../api";
import { numberWithComma } from "../../helper";
import Confirm from "../common/confirm";

function OrderDetail({ visible = false, onClose, id = 0, onSubmit }) {
  const [order, setOrder] = useState({
    full_name: "",
    phone: "",
    email: "",
    address: "",
    note: "",
    is_process: false,
  });
  const [orderTtems, setOrderItems] = useState([]);
  const [onApprove, setOnApprove] = useState(false);

  useEffect(() => {
    if (id) {
      getDetailProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const getDetailProduct = async () => {
    await API.getAPIData(`/api/order/get_order_detail.php?id=${id}`).then(
      (res) => {
        if (res.success) {
          setOrder(res.data);
          setOrderItems(res.order_items);
        } else {
          setOrder({
            full_name: "",
            phone: "",
            email: "",
            address: "",
            note: "",
            is_process: false,
          });
        }
      }
    );
  };

  const submitApprove = async () => {
    await API.getAPIData(`/api/order/approve.php?id=${id}`).then((res) => {
      if (res.success) {
        onSubmit();
        toast.success("Approve sucessfully!");
      } else {
        toast.error(res.message);
      }
    });
    onCloseApprove();
  };

  const onUpdate = () => {
    setOnApprove(true);
  };

  const onCloseApprove = () => {
    setOnApprove(false);
  };

  return (
    <Modal size="lg" isOpen={visible} style={{ zIndex: "100" }}>
      <Confirm
        visible={onApprove}
        header={"Approve Order"}
        title={"Are you sure you want to approve order?"}
        onConfirm={submitApprove}
        onClose={onCloseApprove}
      />
      <ModalHeader>
        {id ? "Detail " : "Create Orders"}
        {order.is_process === "1" ? (
          <span style={{ color: "##4caf50" }}>Processed</span>
        ) : (
          <span style={{ color: "red" }}>Processing</span>
        )}
      </ModalHeader>
      <ModalBody>
        <Row className="p-2">
          <Col xs={12}>
            <input
              type="text"
              className="form-control"
              value={order.full_name}
              placeholder="Full Name"
              disabled={true}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col xs={12}>
            <input
              type="text"
              className="form-control"
              value={order.phone}
              placeholder="Phone"
              disabled={true}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col xs={12}>
            <input
              type="text"
              className="form-control"
              value={order.email}
              placeholder="Email"
              disabled={true}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col xs={12}>
            <input
              type="text"
              className="form-control"
              value={order.address}
              placeholder="Address"
              disabled={true}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col xs={12}>
            <textarea
              type="text"
              className="form-control"
              value={order.note}
              placeholder="Note"
              disabled={true}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col xs={12}>
            <input
              type="text"
              className="form-control"
              value={order.payment_menthod}
              placeholder="payment_menthod"
              disabled={true}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col xs={12}>
            <input
              type="text"
              className="form-control"
              value={
                order.is_payment === "1" ? "Đã thanh toán" : "Chưa thanh toán"
              }
              placeholder="is_payment"
              disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <table className="table align-items-center mb-0">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-xxs font-weight-bold"
                    style={{ width: "50px" }}
                  >
                    Product Name
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bold">
                    Quantity
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bold">
                    Price
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderTtems.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle" style={{ width: "400px" }}>
                        <span>{item.product_name}</span>
                      </td>
                      <td>
                        <span>{numberWithComma(item.quantity)}</span>
                      </td>
                      <td className="align-middle" style={{ width: "300px" }}>
                        <div className="payment-price">
                          {numberWithComma(item.price)}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-small btn-light"
            onClick={() => {
              onClose();
            }}
          >
            Close
          </button>
          {order.is_process === "0" && (
            <Button
              className="bg-gradient-primary"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={() => onUpdate()}
            >
              Approve
            </Button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default OrderDetail;
