import JoditEditor from "jodit-react";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { configJoditEditor } from "../../../contant";
import SelectImage from "../common/select_images";
import { isEmpty } from "../../helper";
import { toast } from "react-toastify";
import API from "../../../api";

function ProductDetail({ visible = false, onClose, id = 0, onSubmit }) {
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [isActivity, setIsActivity] = useState(true);
  const [description, setDescription] = useState("");
  const [error, setError] = useState(false);
  const [openSelectImage, setOpenSelectImage] = useState(false);
  const [image, setImage] = useState("");

  const getDetailProduct = async () => {
    await API.getAPIData(`/api/product/get_product_detail.php?id=${id}`).then(
      (res) => {
        if (res.success) {
          const detail = res.data;
          setProductName(detail.product_name);
          setPrice(detail.price);
          setIsActivity(detail.is_activity === "0" ? false : true);
          setDescription(detail.description);
          setImage(detail.url_image);
        } else {
          setProductName("");
          setPrice("");
          setIsActivity(true);
          setDescription("");
          setError(false);
          setImage("");
        }
      }
    );
  };

  useEffect(() => {
    if (id) {
      getDetailProduct();
    } else {
      setProductName("");
      setPrice("");
      setIsActivity(true);
      setDescription("");
      setError(false);
      setImage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onOpenSelectImage = () => {
    setOpenSelectImage(!openSelectImage);
  };

  const onChangeImage = (img_name) => {
    setImage(img_name);
    onOpenSelectImage();
  };

  const onUpdate = () => {
    setError(false);
    if (isEmpty(productName)) {
      setError(true);
      toast.error("Product Name is not empty!");
      return;
    }
    if (isEmpty(price)) {
      setError(true);
      toast.error("Price is not empty!");
      return;
    }
    if (isEmpty(image)) {
      setError(true);
      toast.error("Image is not empty!");
      return;
    }

    if (isEmpty(description)) {
      setError(true);
      toast.error("Descript is not empty!");
      return;
    }

    onSubmit({
      id: id,
      product_name: productName,
      price: price,
      img_name: image,
      is_activity: isActivity,
      description: description,
    });
  };

  return (
    <Modal size="lg" isOpen={visible} style={{ zIndex: "100" }}>
      <SelectImage
        visible={openSelectImage}
        onClose={onOpenSelectImage}
        onSave={onChangeImage}
      />
      <ModalHeader>{id ? "Edit Product" : "Create Product"}</ModalHeader>
      <ModalBody>
        <Row className="p-2">
          <Col xs={12}>
            <input
              type="text"
              className={
                error && isEmpty(productName)
                  ? "form-control input-error"
                  : "form-control"
              }
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              placeholder="Product name"
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col xs={12}>
            <input
              type="text"
              className={
                error && isEmpty(price)
                  ? "form-control input-error"
                  : "form-control"
              }
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Show Price"
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col xs={12} className="d-flex">
            <input
              type="checkbox"
              style={{ width: "24px", height: "24px" }}
              checked={isActivity}
              onChange={() => {
                setIsActivity(!isActivity);
              }}
            />
            <span className="text-note px-2">
              Product activity will be displayed on the sales page
            </span>
          </Col>
        </Row>
        <Row className="p-2">
          <Col xs={12} style={{ display: "flex" }}>
            <span style={{ alignSelf: "center" }}>https://smartads.vn</span>
            <input
              type="text"
              className={
                error && isEmpty(image)
                  ? "form-control input-error"
                  : "form-control"
              }
              value={image}
              onChange={(e) => setImage(e.target.value)}
              disabled
              placeholder="/File name"
              style={{
                backgroundColor: "#fff",
                borderColor: "#fff",
                paddingLeft: "0px",
              }}
            />
            <div style={{ width: "50px", height: "35px" }}>
              <img src={"/api/image/" + image} alt={"img"} />
            </div>
            <button
              className="btn-edit"
              onClick={() => {
                onOpenSelectImage();
              }}
            >
              Edit
            </button>
          </Col>
        </Row>
        <Row className="p-2">
          <Col
            xs={12}
            className={
              error && isEmpty(description)
                ? "cs_jodit_editor input-error"
                : "cs_jodit_editor"
            }
          >
            <JoditEditor
              value={description}
              config={configJoditEditor}
              tabIndex={200}
              onBlur={(e) => setDescription(e)}
              placeholder="Description"
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-small btn-light"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
          <Button
            className="bg-gradient-primary"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => onUpdate()}
          >
            Confirm
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default ProductDetail;
