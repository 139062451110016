import React, { useEffect } from "react";
import * as Icon from "react-feather";
import API from "../../../api";
import { useNavigate } from "react-router-dom";

function NavBar() {
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const session_login = async () => {
    API.getAPIData("/api/session_login.php").then((res) => {
      if (!res.success) {
        navigate("/admin/login");
      }
    });
  };

  useEffect(() => {
    session_login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideNav = () => {
    const sidenav = document.getElementById("sidenav-main");
    sidenav.classList.add("nav-xl-none");
  };

  const menu = [
    {
      title: "Dashboard",
      url: "/admin/dashboard",
      ion: <Icon.Grid />,
    },
    {
      title: "User",
      url: "/admin/users",
      ion: <Icon.Users />,
    },
    {
      title: "Home",
      url: "/admin/home",
      ion: <Icon.Home />,
    },
    {
      title: "Service",
      url: "/admin/services",
      ion: <Icon.Server />,
    },
    {
      title: "Project",
      url: "/admin/projects",
      ion: <Icon.Grid />,
    },
    {
      title: "Culture careers",
      url: "/admin/Culture_careers",
      ion: <Icon.Shield />,
    },
    {
      title: "Products",
      url: "/admin/products",
      ion: <Icon.Trello />,
    },
    {
      title: "Orders",
      url: "/admin/orders",
      ion: <Icon.ShoppingBag />,
    },
    {
      title: "VietQR Transaction",
      url: "/admin/vietqr-transaction",
      ion: <Icon.ShoppingBag />,
    },
    {
      title: "Polices",
      url: "/admin/polices",
      ion: <Icon.Tv />,
    },
    {
      title: "List Logo",
      url: "/admin/list-logo",
      ion: <Icon.List />,
    },
    {
      title: "List Our Work",
      url: "/admin/list-our-work",
      ion: <Icon.Briefcase />,
    },
    {
      title: "Type Working",
      url: "/admin/type-working",
      ion: <Icon.Table />,
    },
    {
      title: "List Working",
      url: "/admin/list-working",
      ion: <Icon.Book />,
    },
    {
      title: "Vacancies",
      url: "/admin/vacancies",
      ion: <Icon.Twitch />,
    },
    {
      title: "Company information",
      url: "/admin/setting",
      ion: <Icon.Settings />,
    },
    {
      title: "Upload Image",
      url: "/admin/upload-images",
      ion: <Icon.Image />,
    },
    // {
    //   title: "Upload Videos",
    //   url: "/admin/upload-videos",
    //   ion: <Icon.Video />,
    // },
  ];

  return (
    <aside
      className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-primary nav-xl-none"
      id="sidenav-main"
    >
      <div className="sidenav-header">
        <span
          className="cursor-pointer text-white d-xl-none position-absolute end-0 top-0 opacity-5 p-2"
          onClick={() => hideNav()}
        >
          X
        </span>
        <a
          className="m-3 d-flex justify-content-center text-decoration-none"
          href="/admin/dashboard"
        >
          <span className="ms-1 font-weight-bold text-white">Smart Ads</span>
        </a>
      </div>
      <hr className="horizontal light mt-0 mb-2" />
      <div
        className="collapse navbar-collapse w-auto"
        id="sidenav-collapse-main"
      >
        <ul className="navbar-nav">
          {menu.map((menu, index) => {
            return (
              <li className="nav-item" key={`menu-${index}`}>
                <a
                  className={
                    pathname === menu.url
                      ? "nav-link text-white active bg-gradient-primary"
                      : "nav-link text-white "
                  }
                  href={menu.url}
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    {menu.ion}
                  </div>
                  <span className="nav-link-text ms-1">{menu.title}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </aside>
  );
}

export default NavBar;
