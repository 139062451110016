import React, { useState } from "react";
import ImageLogin from "../../assets/images/illustration-login.jpg";
import { useNavigate } from "react-router-dom";
import Loading from "../common/loading";
import { toast } from "react-toastify";
import API from "../../api";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);

  const onLogin = async () => {
    if (email === "" || email === null) {
      return toast.error("Email cannot be empty!");
    }
    if (pass === "" || pass === null) {
      return toast.error("Password cannot be empty!");
    }
    setLoading(true);

    await API.postParam("/api/login.php", {
      email: email,
      password: pass,
    }).then((res) => {
      if (res.success) {
        toast.success("Login successfully!");
        navigate("/admin/dashboard");
      } else {
        toast.error(res.message);
      }
    });
    setLoading(false);
  };

  return (
    <main className="main-content mt-0 dashboard-admin">
      {loading && <Loading />}
      <div className="page-header min-vh-100">
        <div className="container">
          <div className="row">
            <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
              <div
                className="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
                style={{
                  backgroundImage: `url(${ImageLogin})`,
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
              <div className="card card-plain">
                <div className="card-header">
                  <h4 className="font-weight-bolder">Sign In</h4>
                  <p className="mb-0">Enter your email and password</p>
                </div>
                <div className="card-body">
                  {/* eslint-disable-next-line */}
                  <form role="form">
                    <div className="input-group input-group-outline mb-3">
                      {!email && <label className="form-label">Email</label>}
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            onLogin();
                          }
                        }}
                      />
                    </div>
                    <div className="input-group input-group-outline mb-3">
                      {!pass && <label className="form-label">Password</label>}
                      <input
                        type="password"
                        className="form-control"
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            onLogin();
                          }
                        }}
                      />
                    </div>

                    {/* <div className="form-check form-check-info text-start ps-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        checked
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        Remenber me
                      </label>
                    </div> */}
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0"
                        onClick={() => onLogin()}
                      >
                        Sign In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;
