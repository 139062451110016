import React, { useEffect, useState } from "react";
import NavBar from "../common/navbar";
import Header from "../common/header";
import API from "../../../api";
import Confirm from "../common/confirm";
import { toast } from "react-toastify";
import ProductDetail from "./product_detail";
import { caculateOffSet, caculatePage } from "../../helper";
import Paginator from "../common/Paginato";

function Products() {
  const [products, setProducts] = useState([]);
  const [idDelete, setidDelete] = useState(0);
  const [openDetail, setOpenDetail] = useState(false);
  const [detailId, setDetailId] = useState(0);
  const [page, setPage] = useState({
    page: 1,
    size: 10,
    totalPages: 10,
  });

  useEffect(() => {
    getProducts(1, 10);
  }, []);

  const getProducts = async (newPage, newSize) => {
    await API.getAPIData(
      `/api/product/get_product_list.php?offset=${caculateOffSet(
        newPage,
        newSize
      )}&size=${newSize}`
    ).then((res) => {
      if (res.success) {
        setProducts(res.data);
        setPage({
          page: newPage,
          size: 10,
          totalPages: caculatePage(res.total, newSize),
        });
      } else {
        setProducts([]);
      }
    });
  };

  const onDelete = (id) => {
    setidDelete(id);
  };

  const submitDelete = () => {
    API.postParam("/api/product/delete.php", { id: idDelete }).then((res) => {
      if (res.success) {
        onCloseDelete();
        getProducts();
        toast.success("Deleted successfully.");
      } else {
        toast.error("Delete error!");
      }
    });
  };

  const onCloseDelete = () => {
    setidDelete(0);
  };

  const onCloseDetail = () => {
    setDetailId(0);
    setOpenDetail(false);
  };

  const onSubmit = (data) => {
    API.postParam("/api/product/create_product.php", data).then((res) => {
      if (res.success) {
        getProducts(1, 10);
        onCloseDetail();
        toast.success("Process Successfully.");
      } else {
        toast.error(res.message);
      }
    });
  };

  const onEdit = (idDetail) => {
    setDetailId(idDetail);
    setOpenDetail(true);
  };

  const onPageChange = (newPage) => {
    getProducts(newPage, page.size);
  };

  return (
    <section className="dashboard-admin">
      <NavBar />
      <ProductDetail
        visible={openDetail}
        onClose={onCloseDetail}
        onSubmit={onSubmit}
        id={detailId}
      />
      <Confirm
        visible={!!idDelete}
        header={"Delete Product"}
        title={"Are you sure you want to delete product?"}
        onConfirm={submitDelete}
        onClose={onCloseDelete}
      />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Product List" />
        <div className="container-fluid py-4">
          <div className="d-flex justify-content-end">
            <button
              style={{ border: "0px" }}
              className="justify-content-end badge badge-sm bg-gradient-primary p-2"
              onClick={() => setOpenDetail(true)}
            >
              <span className="p-2">Add new</span>
            </button>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th
                            className="text-uppercase text-xxs font-weight-bold"
                            style={{ width: "50px" }}
                          >
                            Image
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Product Name
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Is Activity
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Description
                          </th>
                          <th
                            style={{ width: "70px" }}
                            className="text-uppercase text-xxs font-weight-bold"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="align-middle">
                                <img
                                  src={"/api/image/" + item.url_image}
                                  alt="img"
                                  style={{ width: "50px" }}
                                />
                              </td>
                              <td>
                                <span>{item.product_name}</span>
                              </td>
                              <td className="align-middle">
                                <span>
                                  {item.is_activity === "0" ? "False" : "True"}
                                </span>
                              </td>
                              <td
                                className="align-middle"
                                style={{ width: "200px" }}
                              >
                                <span className="text-inline-40-percent">
                                  {item.description}
                                </span>
                              </td>
                              <td className="align-middle justify-content-space-between">
                                <button
                                  className="btn-edit-list"
                                  onClick={() => onEdit(item.id)}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn-delete-list"
                                  style={{ marginLeft: "2px" }}
                                  onClick={() => onDelete(item.id)}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {caculatePage(page.totalPages, page.size) > 0 && (
                      <Paginator
                        {...page}
                        onPageChange={(newpage) => onPageChange(newpage)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
}

export default Products;
