export const configJoditEditor = {
  readonly: false,
  controls: {
    font: {
      list: {
        "system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji":
          "For web",
      },
    },
    fontsize: {
      list: [
        "8",
        "9",
        "10",
        "11",
        "12",
        "14",
        "16",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "30",
        "36",
        "48",
        "60",
        "72",
        "96",
      ],
    },
  },
};

export const paymentMenthodType = {
  cod: "COD",
  qrCode: "QR",
};
