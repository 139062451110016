import React, { useState, useEffect } from "react";
import "./material-dashboard.css";
import NavBar from "./common/navbar";
import Header from "./common/header";
import * as dataTable from "../constant_table";
import Loading from "../common/loading";
import { Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import * as Icon from "react-feather";
import Confirm from "./common/confirm";
import SelectImage from "./common/select_images";
import API from "../../api";
import { useNavigate } from "react-router-dom";

function ListLogo() {
  const navigate = useNavigate();
  document.title = "List Logo Partner";
  const [id, setID] = useState("");
  const [name, setName] = useState("");
  const [listImages, setListImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openSelectImg, setOpenSelectImg] = useState(false);
  const [indexSelect, setIndexSelect] = useState("");

  useEffect(() => {
    getPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPageView = async () => {
    await API.getAPIData(
      `/api/post_list/get_list.php?page_view=${dataTable.TABLE_LIST_LOGO}`
    ).then((res) => {
      if (res.success) {
        const listLogo = res.data;

        if (listLogo.length > 0) {
          const logo = listLogo[0];

          setName(logo.title);
          setID(logo.id);
          const stringUrl = logo.description;
          const listUrl = stringUrl.split(",") || [];
          setListImages(listUrl);
          setLoading(false);
        }
      } else {
        if (res.code === "re-login") {
          navigate("/admin/login");
        }
        toast.error(res.message);
      }

      setLoading(false);
    });
  };

  const onChangeValue = (value) => {
    setName(value);
  };

  const onAddNewArticle = () => {
    const list = [...listImages, ""];
    setListImages(list);
  };

  const onOpenSelectImg = () => {
    setOpenSelectImg(!openSelectImg);
  };

  const onChangeSelectImage = (index) => {
    setIndexSelect(index);
    onOpenSelectImg();
  };

  const onChangeImage = (image) => {
    setLoading(true);
    const list = listImages;
    list[indexSelect] = image;
    setListImages([...list]);
    onOpenSelectImg();
    setLoading(false);
  };

  const onOpenConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  const onSave = async () => {
    setLoadingUpdate(true);
    onOpenConfirm();

    const listImagesString = listImages.join(",") || "";

    const updateFields = {
      id: id || 0,
      type: "logo-list",
      title: name,
      sub_title: "",
      description: listImagesString,
      url_image: "",
      button_name: "",
      to_url: "",
      sort_index: 0,
      page_view: dataTable.TABLE_LIST_LOGO,
    };

    API.postParam("/api/post_list/insert_or_update_detail.php", updateFields)
      .then((res) => {
        if (res.success) {
          toast.success("Update successfully!");
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });

    setLoadingUpdate(false);
  };

  const onRemove = (index) => {
    setLoading(true);
    const list = [...listImages];
    list.splice(index, 1);
    setListImages(list);
    setLoading(false);
  };

  return (
    <section className="dashboard-admin">
      {(loading || loadingUpdate) && <Loading />}
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Services" />
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body px-0" style={{ margin: "20px" }}>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Title:</label>
                    </Col>
                    <Col xs={10}>
                      <input
                        type="text"
                        style={{ with: "98%", marginLeft: "15px" }}
                        className={"form-control"}
                        value={name}
                        onChange={(e) => onChangeValue(e.target.value)}
                      />
                    </Col>
                  </Row>

                  {listImages.map((value, index) => {
                    return (
                      <Row className="p-2" key={index}>
                        <Col xs={2}>
                          <label>Url Image {index + 1}:</label>
                        </Col>
                        <Col xs={10} style={{ display: "flex" }}>
                          <input
                            type="text"
                            style={{ with: "98%", marginLeft: "15px" }}
                            className={"form-control"}
                            defaultValue={value}
                          />
                          <div style={{ width: "50px", height: "35px" }}>
                            <img src={"/api/image/" + value} alt={value} />
                          </div>
                          <button
                            className="btn-edit"
                            onClick={() => onChangeSelectImage(index)}
                          >
                            Edit
                          </button>

                          <button
                            className="btn-x-remove"
                            onClick={() => onRemove(index)}
                          >
                            x
                          </button>
                        </Col>
                      </Row>
                    );
                  })}
                  <div className="d-flex justify-content-end">
                    <button
                      style={{ border: "0px" }}
                      className="justify-content-end badge badge-sm bg-gradient-primary p-2 m-2"
                      onClick={() => onOpenConfirm()}
                    >
                      <span className="p-2">Save</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            style={{ border: "0px" }}
            className="badge badge-sm bg-gradient-success"
            onClick={onAddNewArticle}
          >
            <Icon.Plus />
          </button>
        </div>
      </main>
      <Confirm
        visible={openConfirm}
        onClose={onOpenConfirm}
        onConfirm={onSave}
        header={"Update list logo"}
        title={"Are you sure you want to update infor?"}
      />
      <SelectImage
        visible={openSelectImg}
        onClose={onOpenSelectImg}
        onSave={onChangeImage}
      />
    </section>
  );
}

export default ListLogo;
