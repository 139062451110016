import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { toast } from "react-toastify";
// import { importAll } from "../../helper";

toast.configure();

export default function SelectVideo({
  visible,
  onClose,
  onSave,
  videoLocation,
}) {
  // eslint-disable-next-line
  const [data, _setData] = useState([]);
  const [videoSelect, setVideoSelect] = useState("");

  useEffect(() => {
    // const videMp4 = importAll(
    //   require.context("../../../assets/video", false, /\.(mp4)$/)
    // );
    // setData(Object.values(videMp4));
    // if (videoLocation) {
    //   setVideoSelect(videoLocation);
    // }
  }, [visible]);

  const onSelectRadio = (e) => {
    setVideoSelect(e);
  };

  return (
    <Modal size="lg" isOpen={visible}>
      <ModalHeader>Select Video</ModalHeader>
      <ModalBody>
        <table className="table align-items-center mb-0">
          <tbody>
            {data.map((value, index) => {
              return (
                <tr key={index}>
                  <th>
                    <input
                      type="radio"
                      id={"radio_video" + index}
                      name="radio_video"
                      value={value}
                      onChange={(e) => onSelectRadio(e.target.value)}
                      checked={value === videoSelect}
                    />
                      <label for={"radio_video" + index}> {value}</label>
                  </th>
                </tr>
              );
            })}
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-small btn-light"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
          <Button
            className="bg-gradient-primary"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => onSave(videoSelect)}
          >
            Confirm
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
