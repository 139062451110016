import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import * as Icon from "react-feather";
import QRCode from "react-qr-code";
import { numberWithComma } from "../helper";
import API from "../../api";
import { toast } from "react-toastify";

export default function PopupQrCode(props) {
  const { visible = false, onClose, data, onSetNotify } = props;
  const [qrCode, setQrCode] = useState("");

  const getQrCode = async () => {
    const params = {
      amount: parseInt(data.amount),
      order_id: data.orderCode,
    };
    await API.postParam("/api/order/get_qr.php", params).then((res) => {
      if (res.success) {
        setQrCode(res.qrCode);
      }
    });
  };

  useEffect(() => {
    if (visible) {
      getQrCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const checkPayment = async (orderCode) => {
    await API.getAPIData(
      `/api/order/check_order_payment.php?order_code=${orderCode}`
    ).then((res) => {
      if (res.success) {
        validateData(res.data);
      } else {
        toast.error(
          "Thanh toán chưa thành công vui lòng liên hệ SDT: 028 3920 8480 để được hỗ trợ!"
        );
      }
    });
  };

  const validateData = (order) => {
    if (order == null) {
      onSetNotify(
        "Không tìm thấy đơn hàng của bạn vui lòng kiểm tra lại",
        false
      );
      return;
    }

    if (order.is_payment === "1") {
      onSetNotify(
        "Thanh toán thành công Cảm ơn bạn đã tin dùng dịch vụ của chúng tôi.",
        true
      );
      return;
    }

    onSetNotify(
      "Đơn hàng của bạn chưa thanh toán vui lòng kiểm tra lại.",
      false
    );
  };

  return (
    <Modal size="md" isOpen={visible} className="popup-product-review">
      <div className="popup-header">
        <div className="title font-weight-bold">
          Quét mã ứng dụng ngân hàng/ Ví điện tử
        </div>
        <div className="x-lose">
          <Icon.X onClick={() => onClose()} />
        </div>
      </div>
      <ModalBody>
        <div className="d-flex justify-content-center">
          <div className="text-center">
            <div
              style={{
                width: "200px",
                backgroundColor: "#F2ECF1",
              }}
              className="m-auto p-3"
            >
              {qrCode ? (
                <QRCode
                  value={qrCode}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                />
              ) : (
                <div className="fw-semibold">Loading QR Code....</div>
              )}
            </div>
            <div
              className="mt-3 text-center font-weight-bold fs-3"
              style={{ color: "#e37d20" }}
            >
              {numberWithComma(data.amount)} vnd
            </div>
            <div className="font-weight-light mt-2">
              CTY TNHH TT UNG DUNG THONG THAI
            </div>
            <div className="font-weight-bold mt-2">6913688888</div>
            <div className="font-weight-light mt-1">
              MB - Ngân hàng TMCP Quân đội
            </div>
            <div className="font-weight-light mt-1">
              Mã hoá đơn: {data.orderCode}
            </div>
            <div className="font-weight-light mt-1">
              Nội dung thanh toán: {data.orderCode} dich vu SMART
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-center w-100">
          <Button
            className="btn-sent-review"
            color="primary"
            onClick={() => checkPayment(data.orderCode)}
          >
            Xác nhận đã thanh toán
          </Button>
        </div>
        <div className="d-flex justify-content-center w-100">
          <Button
            className="btn-sent-review"
            color="secondary"
            onClick={() => onClose()}
          >
            Huỷ thanh toán
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
