import React from "react";

function ArticleTitle({ title, content }) {
  return (
    <article className="m27 break-out">
      <div className="container m27__container">
        <h1 className="m27__heading uppercase heading--1">{title}</h1>
        <p className="m27__sub-heading heading--5">{content}</p>
        <span className="m27__terms heading--6"></span>
      </div>
    </article>
  );
}

export default ArticleTitle;
