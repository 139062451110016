import React, { useState } from "react";
import * as Icon from "react-feather";
import Confirm from "./confirm";
import { useNavigate } from "react-router-dom";
import API from "../../../api";

function Header({ title }) {
  const navigate = useNavigate();
  const [openComfirm, setOpenComfirm] = useState(false);

  const showNav = () => {
    const sidenav = document.getElementById("sidenav-main");
    sidenav.classList.remove("nav-xl-none");
  };

  const onChangePopup = async () => {
    setOpenComfirm(!openComfirm);
  };

  const onSubmit = async () => {
    setOpenComfirm(!openComfirm);
    await API.getAPIData("/api/logout.php").then((res) => {
      if (res.success) {
        navigate("/admin/login");
      } else {
        navigate("/admin/login");
      }
    });
  };

  return (
    <nav
      className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
      id="navbarBlur"
      navbar-scroll="true"
    >
      <div className="container-fluid py-1 px-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm">Pages</li>
            <li
              className="breadcrumb-item text-sm text-dark active"
              aria-current="page"
            >
              {title}
            </li>
          </ol>
        </nav>
        <div className=" collapse d-flex mt-sm-0 mt-2 me-md-0 me-sm-4">
          <ul className="navbar-nav d-flex justify-content-end">
            <li className="nav-item d-flex align-items-center d-sm-inline d-none">
              <button
                className="d-flex"
                style={{ border: "0px", backgroundColor: "#fff" }}
                onClick={() => onChangePopup()}
              >
                <Icon.LogOut />
                <span className="d-sm-inline d-none">Sign Out</span>
              </button>
            </li>
            <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
              <div className="nav-link text-body p-0" onClick={() => showNav()}>
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Confirm
        visible={openComfirm}
        onClose={onChangePopup}
        onConfirm={onSubmit}
        header={"Sign Out"}
        title={"Are you sure you want to sign out?"}
      />
    </nav>
  );
}

export default Header;
