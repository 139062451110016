import React, { useState, useEffect } from "react";
import "./material-dashboard.css";
import NavBar from "./common/navbar";
import Header from "./common/header";
import Loading from "../common/loading";
import CreateUser from "./create_user";
import { toast } from "react-toastify";
// import Confirm from "./common/confirm";
import API from "../../api";
import { useNavigate } from "react-router-dom";

function Users() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [userIdDelete, setUserIdDelete] = useState("");
  const [userId, setUserID] = useState({});

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = async () => {
    setLoading(true);
    await API.getAPIData("/api/users/get_list.php")
      .then((res) => {
        if (res.success) {
          setUsers(res.data);
        } else {
          if (res.code === "re-login") {
            navigate("/admin/login");
          }
          toast.error(res.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onOpenModel = () => {
    setOpenModal(!openModal);
  };

  const onCloseEdit = () => {
    onOpenModel();
    setUserID(0);
    getUser();
  };

  const onEdit = (id) => {
    onOpenModel();
    setUserID(id);
  };

  // const onDelete = async () => {
  //   onOpenConfirm();
  //   setLoading(true);
  //   await API.postParam("/api/users/delete_user.php", { id: userIdDelete })
  //     .then((res) => {
  //       if (res.success) {
  //         toast.success("Deleted successfully!");
  //       } else {
  //         if (res.code === "re-login") {
  //           navigate("/admin/login");
  //         }
  //         toast.error(res.message);
  //       }
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  // const onOpenConfirm = () => {
  //   setOpenConfirm(!openConfirm);
  // };

  // const onSetDeletedUser = async (id) => {
  //   setUserIdDelete(id);
  //   onOpenConfirm();
  // };
  return (
    <section className="dashboard-admin">
      {loading && <Loading />}
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Users" />
        <div className="container-fluid py-4">
          <div className="d-flex justify-content-end">
            <button
              style={{ border: "0px" }}
              className="justify-content-end badge badge-sm bg-gradient-primary p-2"
              onClick={() => onOpenModel()}
            >
              <span className="p-2">Create User</span>
            </button>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Author
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                            Function
                          </th>
                          <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Status
                          </th>
                          <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Employed
                          </th>
                          <th
                            style={{ width: "70px" }}
                            className="text-secondary opacity-7"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((item, index) => {
                          // const sec = item.created_at.seconds;
                          // const time = new Date(sec * 1000).toLocaleDateString(
                          //   "vi-VN"
                          // );
                          return (
                            <tr key={index}>
                              <td>
                                <div className="d-flex px-2 py-1">
                                  <div className="d-flex flex-column justify-content-center">
                                    <div className="mb-0 text-sm">
                                      {item.name}
                                    </div>
                                    <p className="text-xs text-secondary mb-0">
                                      {item.phone}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="text-xs font-weight-bold mb-0">
                                  {item.role}
                                </p>
                                <p className="text-xs text-secondary mb-0">
                                  {item.phone}
                                </p>
                              </td>
                              <td className="align-middle text-center text-sm">
                                <span
                                  className={
                                    item.is_activity === "1"
                                      ? "badge badge-sm bg-gradient-success"
                                      : "badge badge-sm bg-gradient-secondary"
                                  }
                                >
                                  {item.is_activity === "1"
                                    ? "Online"
                                    : "Offline"}
                                </span>
                              </td>
                              <td className="align-middle text-center">
                                <span className="text-secondary text-xs font-weight-bold">
                                  {item.created_at}
                                </span>
                              </td>
                              <td className="align-middle justify-content-space-between">
                                <button
                                  className="btn-edit-list"
                                  onClick={() => onEdit(item.id)}
                                >
                                  Edit
                                </button>
                                {/* <button
                                  className="btn-delete-list"
                                  style={{ marginLeft: "2px" }}
                                  onClick={() => onSetDeletedUser(item.id)}
                                >
                                  Delete
                                </button> */}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateUser
          visible={openModal}
          onClose={onCloseEdit}
          setLoading={setLoading}
          userId={userId}
        />
        {/* <Confirm
          visible={openConfirm}
          onClose={onOpenConfirm}
          onConfirm={onDelete}
          header={"Delete user"}
          title={"Are you sure you want to delete user?"}
        /> */}
      </main>
    </section>
  );
}

export default Users;
