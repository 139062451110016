import React, { useEffect, useState } from "react";
import Header from "./common/header";
import Footer from "./common/footer";
import API from "../api";
import emptyCartImg from "../assets/images/empty-cart.png";
import Confirm from "./Admin/common/confirm";
import { emailValidation, isEmpty, numberWithComma } from "./helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { paymentMenthodType } from "../contant";
import PopupQrCode from "./common/popup_qrCode";
import PopupNotify from "./common/popup_notify";

function ProductCart() {
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [logo, setLogo] = useState("");
  const [products, setProduct] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [productDelete, setProductDelete] = useState({ product_name: "" });
  const [loadding, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [note, setNote] = useState("");
  const [error, setError] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const [paymentMenthod, setPaymentMenthod] = useState(paymentMenthodType.cod);
  const [isQrPopup, setIsQrPopup] = useState(false);
  const [dataQrCode, setDataQrCode] = useState({ orderId: "", amount: 0 });
  const [notify, setNotify] = useState({ message: "", isSuccess: false });
  const [openNotify, setOpenNotify] = useState(false);

  useEffect(() => {
    getCompanyinfo();
    getToCart();
  }, []);

  const getCompanyinfo = async () => {
    API.getAPIData("/api/company/get_company_information.php").then((res) => {
      if (res.success) {
        const data = res.data;

        setData(data);
        setLogo(data.url_logo);
      }
    });
  };

  const getToCart = async () => {
    await API.getAPIData("/api/order/get_to_cart.php").then((res) => {
      if (res.success) {
        const listCart = res.data;
        setProduct(listCart);
        const value = (listCart || []).reduce((acc, cur) => {
          return Number.isInteger(parseInt(cur.price))
            ? acc + (parseInt(cur.price) || 0)
            : "";
        }, 0);
        setTotalAmount(value);
      }
    });
  };

  const onRemoteProductCart = (item) => {
    setProductDelete(item);
    setOpenConfirm(true);
  };

  const onClose = () => {
    setOpenConfirm(false);
    setProductDelete({ product_name: "" });
  };

  const onRemote = () => {
    setLoading(false);
    API.getAPIData(
      `/api/order/remote-product-cart.php?id=${productDelete.id}`
    ).then((res) => {
      if (res.success) {
        setLoading(true);
        getToCart();
      }
    });

    onClose();
  };

  const onCompleteOrder = async () => {
    setError(false);
    if (isEmpty(fullName)) {
      toast.error("Vui lòng nhập Họ Tên!");
      setError(true);
      return;
    }

    if (isEmpty(phone)) {
      toast.error("Vui lòng nhập số điện thoại!");
      setError(true);
      return;
    }

    if (!emailValidation(email)) {
      toast.error("Vui lòng kiểm tra lại email!");
      setError(true);
      return;
    }

    if (!isRead) {
      toast.error(
        "Vui lòng chọn vào ô Tôi đồng chính sách thanh toán tại SMARTADS.VN!"
      );
      return;
    }

    const uuid = uuidv4().replace(/-/g, ""); // Tạo UUID và loại bỏ ký tự `-`
    const randomChars = uuid.slice(0, 12);
    const orderCode = randomChars.toUpperCase();

    setLoading(true);
    await API.postParam("/api/order/create_order.php", {
      full_name: fullName,
      phone: phone,
      email: email,
      address: address,
      note: note,
      order_code: orderCode,
      payment_menthod: paymentMenthod,
      amount: totalAmount,
    }).then((res) => {
      if (res.success) {
        if (paymentMenthod === paymentMenthodType.cod) {
          navigate("/completed");
        } else {
          setIsQrPopup(true);
          setDataQrCode({
            orderCode: orderCode,
            amount: totalAmount,
          });
        }
      } else {
        toast.error(res.message);
      }
    });
    setLoading(false);
  };

  const onCloseQR = () => {
    setIsQrPopup(false);
  };

  const onCompleted = () => {
    navigate("/product");
  };

  const onSetNotify = (message, isSuccess) => {
    setNotify({
      message: message,
      isSuccess: isSuccess,
    });
    setOpenNotify(true);
  };

  const onCloseNotify = () => {
    setNotify({
      message: "",
      isSuccess: false,
    });
    setOpenNotify(false);
  };

  const onSuccessNotify = () => {
    setNotify({
      message: "",
      isSuccess: false,
    });
    setOpenNotify(false);

    setTimeout(() => {
      navigate("/completed");
    }, 1000);
  };

  return (
    <section>
      <Confirm
        visible={openConfirm}
        header="Remote Product"
        title={`Do you want to remove product ${productDelete.product_name}?`}
        onClose={onClose}
        onConfirm={onRemote}
      />
      <Header
        backgroundColor={data.color_web}
        imgLogo={logo}
        data={data}
        loading={loadding}
      />
      <div className="container payment-wrap mt-6 mb-8">
        {products.length > 0 ? (
          <div className="row">
            <div className="col-md-6 col-sm-12 mb-3">
              <div className="heading--6 payment-title-header">
                Thông tin đơn hàng
              </div>
              {products.map((value, index) => {
                return (
                  <div className="d-flex payment-wrap-items" key={index}>
                    <div className="payment-image">
                      <img src={"/api/image/" + value.url_image} alt="" />
                    </div>
                    <div className="payment-product-name">
                      <div>{value.product_name}</div>
                      <div>
                        Số lượng: <span>{numberWithComma(value.quantity)}</span>
                      </div>
                    </div>
                    <div className="payment-price">
                      {numberWithComma(value.price)}
                    </div>
                    <div
                      className="remove-x"
                      onClick={() => onRemoteProductCart(value)}
                    >
                      <span className="remove-x-item">X</span>
                    </div>
                  </div>
                );
              })}

              <hr />
              <div className="payment-price-total">
                <div className="heading--7">Tổng:</div>
                <b className="align-self-center">
                  {totalAmount <= 0
                    ? "Chúng tôi sẽ liên hệ bạn trong thời gian sớm nhất"
                    : `${numberWithComma(totalAmount)} VNĐ`}
                </b>
              </div>
              <br />
              <div className="text-center rounded border">
                <div className="mt-1 fw-semibold">
                  CÔNG TY TNHH TIẾP THỊ ỨNG DỤNG THÔNG THÁI
                </div>
                <div className="mt-1">STK: 6913688888</div>
                <div className="mt-1">MB - Ngân hàng TMCP Quân đội</div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 mb-3">
              <div className="heading--6 payment-title-header">
                Thông tin liên hệ
              </div>
              <div className="payment-form-wrap">
                <input
                  className={
                    error && isEmpty(fullName)
                      ? "form-control input-error"
                      : "form-control"
                  }
                  type="text"
                  placeholder="Họ tên *"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  maxLength={50}
                />
              </div>
              <div className="payment-form-wrap">
                <input
                  className={
                    error && isEmpty(phone)
                      ? "form-control input-error"
                      : "form-control"
                  }
                  type="tel"
                  placeholder="Số điện thoại *"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onKeyDown={(event) => {
                    // eslint-disable-next-line
                    if (!/[0-9\B\b84]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={15}
                />
              </div>
              <div className="payment-form-wrap">
                <input
                  className={
                    error && !emailValidation(email)
                      ? "form-control input-error"
                      : "form-control"
                  }
                  type="email"
                  placeholder="Email *"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  maxLength={50}
                />
              </div>

              <div className="heading--6 payment-title-header mt-6">
                Địa chỉ liên hệ
              </div>
              <div className="payment-form-wrap mt-4">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Địa chỉ (Số nhà, tên toà nhà)"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  maxLength={150}
                />
              </div>
              <div className="payment-form-wrap">
                <textarea
                  className="form-control"
                  type="text"
                  placeholder="Ghi chú..."
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  maxLength={200}
                />
              </div>
              <div className="form-group mt-4">
                <input
                  type="checkbox"
                  checked={isRead}
                  onChange={() => setIsRead(!isRead)}
                />{" "}
                Tôi đồng ý với{" "}
                <a
                  href="/police/detail/1"
                  target="_blank"
                  style={{ color: "#0068ff" }}
                >
                  chính sách thanh toán
                </a>
                {", "}
                <a
                  href="/police/detail/3"
                  target="_blank"
                  style={{ color: "#0068ff" }}
                >
                  bảo mật
                </a>
                {", "}
                <a
                  href="/police/detail/2"
                  target="_blank"
                  style={{ color: "#0068ff" }}
                >
                  giao hàng, kiểm hàng, bảo hàng và đổi trả
                </a>{" "}
                tại SMARTADS.VN
                <div className="mt-2">
                  Chúng tôi sẽ liên hệ đến bạn sau khi hoàn tất đơn hàng
                </div>
              </div>
              <div className="mt-6">
                <input
                  type="radio"
                  id="age1"
                  name="paymentmenthod"
                  value={paymentMenthodType.cod}
                  onChange={(e) => setPaymentMenthod(e.target.value)}
                  checked={paymentMenthod === paymentMenthodType.cod}
                />
                <label htmlFor="age1">Thanh toán tiền mặt (COD)</label>
                <br />
                <input
                  type="radio"
                  id="age2"
                  name="paymentmenthod"
                  value={paymentMenthodType.qrCode}
                  onChange={(e) => setPaymentMenthod(e.target.value)}
                  checked={paymentMenthod === paymentMenthodType.qrCode}
                />
                <label htmlFor="age2">Thanh toán chuyển khoản (VietQR)</label>
                <br />
              </div>
              <div className="mt-4">
                <button
                  className={
                    loadding
                      ? "buton-payment-cart-processing"
                      : "buton-payment-cart"
                  }
                  onClick={() => onCompleteOrder()}
                  disabled={loadding}
                >
                  {loadding
                    ? "Tiến hành đặt hàng..."
                    : paymentMenthod === paymentMenthodType.cod
                    ? "Hoàn tất đơn hàng"
                    : "Quét mã QR"}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <img src={emptyCartImg} alt="empty" className="cart-empty" />
            <div className="content-empty">
              <div className="message">Không có sản phẩm trong giỏ hàng</div>
              <a href="/product"> Chuyển đến trang sản phẩm cung cấp</a>
            </div>
            <></>
          </div>
        )}
      </div>
      <PopupQrCode
        visible={isQrPopup}
        onClose={onCloseQR}
        data={dataQrCode}
        onCompleted={onCompleted}
        onSetNotify={onSetNotify}
      />
      <PopupNotify
        visible={openNotify}
        notify={notify}
        onClose={onCloseNotify}
        onSuccess={onSuccessNotify}
      />
      <Footer />
    </section>
  );
}

export default ProductCart;
