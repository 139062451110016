import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";
// import { DEFAULTE_ROLE } from "../constant";
import { phoneValidation, emailValidation } from "../helper";
import API from "../../api";
import { useNavigate } from "react-router-dom";

export default function CreateUser(props) {
  const navigate = useNavigate();
  const { visible, onClose, userId, setLoading } = props;
  const [userName, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  // const [role, setRole] = useState("");
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (userId > 0) {
      get_detail(userId);
    } else {
      setUserName("");
      setPhone("");
      setEmail("");
      // setRole("");
      setActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const get_detail = async (id) => {
    setLoading(true);
    API.getAPIData(`/api/users/get_detail.php?id=${id}`).then((res) => {
      if (res.success) {
        const data = res.data;
        const is_active = data.is_activity === "1" ? true : false;
        setUserName(data.full_name);
        setPhone(data.phone);
        setEmail(data.email);
        // setRole(data.role);
        setActive(is_active);
      } else {
        if (res.code === "re-login") {
          navigate("/admin/login");
        }
        toast.error(res.message);
      }
    });
    setLoading(false);
  };

  const onSaveUser = async () => {
    if (userName === "" || userName === null) {
      return toast.error("Name is required!");
    }
    if (phone === "" || phone === null) {
      return toast.error("Phone is required!");
    }
    if (!phoneValidation(phone)) {
      return toast.error("Phone is not correct format!");
    }
    if (email === "" || email === null) {
      return toast.error("Email is required!");
    }
    if (!emailValidation(email)) {
      return toast.error("Email is not correct format!");
    }
    // if (role === "" || role === null) {
    //   return toast.error("Role is required!");
    // }
    setLoading(true);

    API.postParam("/api/users/update_detail.php", {
      id: userId,
      full_name: userName,
      phone: phone,
      email: email,
      is_active: active,
    }).then((res) => {
      if (res.success) {
        toast.success("Update user successfully!");
        onClose();
      } else {
        if (res.code === "re-login") {
          navigate("/admin/login");
        }
        toast.error(res.message);
      }
    });
    setLoading(false);
  };
  return (
    <Modal size="lg" isOpen={visible}>
      <ModalHeader>{userId > 0 ? "User info" : "Create user"} </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={3}>
            <label>User Name:</label>
          </Col>
          <Col xs={9}>
            <input
              className={
                !userName ? "form-control input-error" : "form-control"
              }
              type="text"
              value={userName}
              maxLength="150"
              onChange={(e) => setUserName(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={3}>
            <label>Phone:</label>
          </Col>
          <Col xs={9}>
            <input
              type="text"
              className={
                !phoneValidation(phone)
                  ? "form-control input-error"
                  : "form-control"
              }
              value={phone}
              maxLength="10"
              onChange={(e) => setPhone(e.target.value)}
              placeholder="09999999999"
              onKeyPress={(event) => {
                if (!/[0-9.]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={3}>
            <label>Email:</label>
          </Col>
          <Col xs={9}>
            <input
              type="text"
              className={
                !emailValidation(email)
                  ? "form-control input-error"
                  : "form-control"
              }
              value={email}
              maxLength="100"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="NguyenVanA@gmail.com"
            />
          </Col>
        </Row>
        {/* <Row className="mt-2">
          <Col xs={3}>
            <label>Role:</label>
          </Col>
          <Col xs={9}>
            <select
              value={role}
              className={!role ? "form-control input-error" : "form-control"}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="">--Select role--</option>
              {DEFAULTE_ROLE.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </Col>
        </Row> */}
        <Row className="mt-2">
          <Col xs={3}>
            <label>Active:</label>
          </Col>
          <Col xs={9}>
            <input
              style={{ with: "4rem" }}
              type="checkbox"
              checked={active}
              onChange={() => {
                setActive(!active);
              }}
              // createRef
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-small btn-light"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
          <Button
            className="bg-gradient-primary"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => onSaveUser()}
          >
            Confirm
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
