import React from "react";
import parse from "html-react-parser";

function ArticleAnimationLeft({ item = {} }) {
  return (
    <article className="wp-block-luna-m05-image-and-text m05 break-out m05--right">
      <div className="container m05__container">
        <div className="m05__content">
          <h2 className="m05__heading uppercase heading--2">{item.title}</h2>
          <div className="m05__introduction">{parse(item.description)}</div>
          {item.to_url && (
            <div className="m02__button-wrap">
              <a href={item.to_url} className="m02__button button">
                {item.button_name}
                <svg
                  className="svg-icon svg-icon--icon_arrow"
                  role="img"
                  aria-hidden="true"
                >
                  <use href="#sprite-icon_arrow"></use>
                </svg>
              </a>
            </div>
          )}
        </div>
        <figure className="m05__media">
          <img
            src={"/api/image" + item.url_image}
            alt=""
            width="683"
            height="1024"
            className="m05__media__image"
          />
        </figure>
      </div>
    </article>
  );
}

export default ArticleAnimationLeft;
