import JoditEditor from "jodit-react";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { configJoditEditor } from "../../../contant";
import SelectImage from "../common/select_images";
import { isEmpty } from "../../helper";
import { toast } from "react-toastify";
import API from "../../../api";

function PoliceDetail({ visible = false, onClose, id = 0, onSubmit }) {
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [isActivity, setIsActivity] = useState(true);
  const [description, setDescription] = useState("");
  const [error, setError] = useState(false);
  const [openSelectImage, setOpenSelectImage] = useState(false);
  const [image, setImage] = useState("");

  const getDetailPolice = async () => {
    await API.getAPIData(`/api/company/get_police_detail.php?id=${id}`).then(
      (res) => {
        if (res.success) {
          const detail = res.data;
          setTitle(detail.title);
          setIsActivity(detail.is_activity === "0" ? false : true);
          setDescription(detail.description);
          setImage(detail.url_image);
          setSubTitle(detail.sub_title);
        } else {
          setTitle("");
          setIsActivity(true);
          setDescription("");
          setError(false);
          setImage("");
          setSubTitle("");
        }
      }
    );
  };

  useEffect(() => {
    if (id) {
      getDetailPolice();
    } else {
      setTitle("");
      setIsActivity(true);
      setDescription("");
      setError(false);
      setImage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onOpenSelectImage = () => {
    setOpenSelectImage(!openSelectImage);
  };

  const onChangeImage = (img_name) => {
    setImage(img_name);
    onOpenSelectImage();
  };

  const onUpdate = () => {
    setError(false);
    if (isEmpty(title)) {
      setError(true);
      toast.error("Title is not empty!");
      return;
    }
    if (isEmpty(image)) {
      setError(true);
      toast.error("Image is not empty!");
      return;
    }

    if (isEmpty(description)) {
      setError(true);
      toast.error("Descript is not empty!");
      return;
    }

    onSubmit({
      id: id,
      title: title,
      img_name: image,
      is_activity: isActivity,
      description: description,
      sub_title: subTitle,
    });
  };

  return (
    <Modal size="lg" isOpen={visible} style={{ zIndex: "100" }}>
      <SelectImage
        visible={openSelectImage}
        onClose={onOpenSelectImage}
        onSave={onChangeImage}
      />
      <ModalHeader>{id ? "Edit Police" : "Create Police"}</ModalHeader>
      <ModalBody>
        <Row className="p-2">
          <Col xs={12}>
            <input
              type="text"
              className={
                error && isEmpty(title)
                  ? "form-control input-error"
                  : "form-control"
              }
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
              maxLength={100}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col xs={12}>
            <input
              type="text"
              className={
                error && isEmpty(subTitle)
                  ? "form-control input-error"
                  : "form-control"
              }
              value={subTitle}
              onChange={(e) => setSubTitle(e.target.value)}
              placeholder="Short description"
              maxLength={200}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col xs={12} className="d-flex">
            <input
              type="checkbox"
              style={{ width: "24px", height: "24px" }}
              checked={isActivity}
              onChange={() => {
                setIsActivity(!isActivity);
              }}
            />
            <span className="text-note px-2">
              Police activity will be displayed on footer website
            </span>
          </Col>
        </Row>
        <Row className="p-2">
          <Col xs={12} style={{ display: "flex" }}>
            <span>
              https://smartads.vn
              {!isEmpty(image) ? image : <span>/select image</span>}
            </span>

            <button
              className="btn-edit"
              onClick={() => {
                onOpenSelectImage();
              }}
            >
              Select
            </button>
          </Col>

          <div className="">
            <img
              src={"/api/image/" + image}
              alt={"img"}
              className="img-create"
            />
          </div>
          {error && isEmpty(image) && (
            <div className="text-error">**Image not empty</div>
          )}
        </Row>
        <Row className="p-2">
          <Col
            xs={12}
            className={
              error && isEmpty(description)
                ? "cs_jodit_editor input-error"
                : "cs_jodit_editor"
            }
          >
            <JoditEditor
              value={description}
              config={configJoditEditor}
              tabIndex={200}
              onBlur={(e) => setDescription(e)}
              placeholder="Description"
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-small btn-light"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
          <Button
            className="bg-gradient-primary"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => onUpdate()}
          >
            Confirm
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default PoliceDetail;
