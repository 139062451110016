import React from "react";
import parse from "html-react-parser";

function ArticleMedia({ title, sub_title, content, link, imageUrl }) {
  return (
    <article className="wp-block-luna-m02-intro m02 break-out m02--media">
      <div className="container m02__container">
        <div className="m02__content">
          <h1 className="m02__heading uppercase">
            <span className="m02__label heading--6">{title}</span>
            <span className="m02__heading-text heading--1">{sub_title}</span>
          </h1>
          <div className="m02__content-text">{parse(content)}</div>
          {link && (
            <div className="m02__button-wrap">
              <a href={link} className="m02__button button">
                See more
                <svg
                  className="svg-icon svg-icon--icon_arrow"
                  role="img"
                  aria-hidden="true"
                >
                  <use href="#sprite-icon_arrow"></use>
                </svg>
              </a>
            </div>
          )}
        </div>
      </div>
      <figure className="m02__media">
        <img
          src={"/api/image" + imageUrl}
          alt=""
          width="1024"
          height="1016"
          className="m02__media__image"
        />
      </figure>
    </article>
  );
}

export default ArticleMedia;
