import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";
import Confirm from "./confirm";
import API from "../../../api";

export default function CreateTypeWork({
  visible,
  onClose,
  setLoading,
  data = {},
}) {
  const [openComfirm, setOpenComfirm] = useState(false);
  const [name, setName] = useState([]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setName(data.name);
    } else {
      setName("");
    }
  }, [data]);

  const onCreateHome = async () => {
    setOpenComfirm(false);

    if (!validateValue()) return;

    setLoading(true);
    await API.postParam("/api/out_work/create_type.php", {
      id: data.id || 0,
      name: name,
    }).then((res) => {
      if (res.success) {
        toast.success("Update successfully!");
        onClose();
      } else {
        toast.error(res.message);
      }
    });
    setLoading(false);
  };

  const validateValue = () => {
    if (name === "" || name === null || name === undefined) {
      toast.error("Type is required!");
      setOpenComfirm(false);
      return false;
    }

    return true;
  };

  const onChangeOpemConform = () => {
    setOpenComfirm(!openComfirm);
  };

  return (
    <>
      <Modal size="lg" isOpen={visible} style={{ zIndex: "100" }}>
        <ModalHeader>{data.id ? "Edit article" : "Create article"}</ModalHeader>
        <ModalBody>
          <Row className="p-2">
            <Col xs={2}>
              <label>name:</label>
            </Col>
            <Col xs={10}>
              <input
                type="text"
                style={{ with: "98%", marginLeft: "15px" }}
                className={"form-control"}
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength={50}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-small btn-light"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </button>
            <Button
              className="bg-gradient-primary"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={() => setOpenComfirm(true)}
            >
              Confirm
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Confirm
        visible={openComfirm}
        onClose={onChangeOpemConform}
        onConfirm={onCreateHome}
        header={data.id ? "Update type" : "Create type"}
        title={
          data.id
            ? "Are you sure you want to update type?"
            : "Are you sure you want to create type?"
        }
      />
    </>
  );
}
