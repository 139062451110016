import React, { useEffect, useState } from "react";
import NavBar from "../common/navbar";
import Header from "../common/header";
import API from "../../../api";
import { caculateOffSet, caculatePage, numberWithComma } from "../../helper";
import Paginator from "../common/Paginato";

function VietQrTransactions() {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState({
    page: 1,
    size: 10,
    totalPages: 10,
  });

  useEffect(() => {
    getOrders(1, 10);
  }, []);

  const getOrders = async (newPage, newSize) => {
    await API.getAPIData(
      `/api/order/get_vietqr_transaction.php?offset=${caculateOffSet(
        newPage,
        newSize
      )}&size=${newSize}`
    ).then((res) => {
      if (res.success) {
        setTransactions(res.data);
        setPage({
          page: newPage,
          size: 10,
          totalPages: caculatePage(res.total, newSize),
        });
      } else {
        setTransactions([]);
      }
    });
  };

  const onPageChange = (newPage) => {
    getOrders(newPage, page.size);
  };

  return (
    <section className="dashboard-admin">
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="VietQR Transaction" />
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Ngày giao dịch
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Order code
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Mã giao dịch
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Số tiền
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="align-middle">
                                <span>{item.transform_transaction_time}</span>
                              </td>
                              <td className="align-middle">
                                <span>{item.orderId}</span>
                              </td>
                              <td className="align-middle">
                                <span>{item.transaction_id}</span>
                              </td>
                              <td>
                                <span>{numberWithComma(item.amount)}</span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {caculatePage(page.totalPages, page.size) > 0 && (
                      <Paginator
                        {...page}
                        onPageChange={(newpage) => onPageChange(newpage)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
}

export default VietQrTransactions;
