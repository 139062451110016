import React from "react";
import "./material-dashboard.css";
import NavBar from "./common/navbar";
import Header from "./common/header";

function Dasboard() {
  return (
    <section className="dashboard-admin">
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Dashboard" />
        <div className="container-fluid py-4">
          <div className="row">
            <div style={{ textAlign: "center", fontSize: "2.5rem" }}>
              WELCOME TO SMART ADS
            </div>
          </div>
        </div>
      </main>
    </section>
  );
}

export default Dasboard;
