import React, { useState, useEffect } from "react";
import "./material-dashboard.css";
import NavBar from "./common/navbar";
import Header from "./common/header";
import * as dataTable from "../constant_table";
import Loading from "../common/loading";
import { toast } from "react-toastify";
import Confirm from "./common/confirm";
import CreateWork from "./common/create_work";
import { Row } from "reactstrap";
import API from "../../api";
import { useNavigate } from "react-router-dom";

function ListWorking() {
  const navigate = useNavigate();

  const [dataHome, setDataHome] = useState([]);
  const [idEdit, setIdEdit] = useState(0);
  const [openArticle, setOpenArticle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  // const [types, setTypes] = useState([]);

  useEffect(() => {
    getPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPageView = async () => {
    await API.getAPIData(
      "/api/out_work/get_list.php?page_view=list_working"
    ).then((res) => {
      if (res.success) {
        setDataHome(res.data);
      } else {
        if (res.code === "re-login") {
          navigate("/admin/login");
        }
        toast.error(res.message);
      }
    });
    setLoading(false);
  };

  const onAddNewArticle = () => {
    setOpenArticle(!openArticle);
    setIdEdit(0);
  };

  const onEditArticle = (itemId) => {
    setIdEdit(itemId);
    setOpenArticle(true);
  };

  const onOpenConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  const onConfirmDeleted = async (id) => {
    setDeleteID(id);
    onOpenConfirm();
  };

  const onDelete = async () => {
    onOpenConfirm();
    setLoading(true);
    await API.getAPIData(
      `/api/out_work/delete_work_list.php?id=${deleteID}`
    ).then((res) => {
      if (res.success) {
        getPageView();
        toast.error("Deleted successfully!");
      } else {
        toast.error(res.message);
      }
    });
    setLoading(false);
  };

  const onChangeShow = async (item) => {
    setLoading(true);
    await API.getAPIData(
      `/api/out_work/change_activity_work_list.php?id=${item.id}`
    ).then((res) => {
      if (res.success) {
        getPageView();
        toast.success("Update activity successfully!");
      } else {
        toast.error(res.message);
      }
    });
    setLoading(false);
  };

  return (
    <section className="dashboard-admin">
      {(loading || loadingUpdate) && <Loading />}
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Home" />
        <div className="container-fluid py-4">
          {/* <div className="card card-body"> */}
          {/* <Row className="p-2">
              <Col xs={2} style={{ alignSelf: "center" }}>
                <label>Type:</label>
              </Col>
              <Col xs={4}>
                <select
                  style={{ with: "98%", marginLeft: "15px" }}
                  className="form-control"
                  // value={type}
                  // onChange={(e) => setType(e.target.value)}
                >
                  <option value=""></option>
                  {types.map((item, index) => {
                    return (
                      <option key={index} value={item.code}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </Col>
              <Col xs={2} style={{ alignSelf: "center" }}>
                <label>Status:</label>
              </Col>
              <Col xs={4}>
                <select
                  style={{ with: "98%", marginLeft: "15px" }}
                  className="form-control"
                  // value={type}
                  // onChange={(e) => setType(e.target.value)}
                >
                  <option value=""></option>
                  <option value={true}>Visible</option>
                  <option value={false}>Unvisible</option>
                </select>
              </Col>
            </Row> */}
          <Row className="p-2">
            {/* <Col xs={2} style={{ alignSelf: "center" }}></Col>
              <Col xs={3}></Col>
              <Col xs={2} style={{ alignSelf: "center" }}></Col>
              <Col xs={3}></Col>
              <Col xs={2} style={{ alignSelf: "center" }}> */}
            <div className="d-flex justify-content-end">
              <button
                style={{ border: "0px" }}
                className="justify-content-end badge badge-sm bg-gradient-primary p-2"
                onClick={() => onAddNewArticle()}
              >
                <span className="p-2">Add new</span>
              </button>
            </div>
            {/* </Col> */}
          </Row>
          {/* </div> */}
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-xxs font-weight-bold"></th>

                          <th className="text-uppercase text-xxs font-weight-bold">
                            Type
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Title
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Link
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold text-center">
                            Show
                          </th>
                          <th
                            style={{ width: "70px" }}
                            className="text-secondary opacity-7"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataHome.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ width: "20px" }}>
                                <div className="d-flex px-2 py-1">
                                  <div className="d-flex flex-column justify-content-center">
                                    <div className="mb-0 text-sm">
                                      {index + 1}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="align-middle">
                                <span>{item.type}</span>
                              </td>
                              <td className="align-middle">
                                <span>{item.title}</span>
                              </td>
                              <td className="align-middle">
                                <span>{item.link}</span>
                              </td>
                              <td className="align-middle text-center">
                                <input
                                  type="checkbox"
                                  checked={item.is_activity === "1"}
                                  onChange={() => onChangeShow(item)}
                                />
                              </td>
                              <td className="align-middle justify-content-space-between">
                                <button
                                  className="btn-edit-list"
                                  onClick={() => onEditArticle(item.id)}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn-delete-list"
                                  style={{ marginLeft: "2px" }}
                                  onClick={() => onConfirmDeleted(item.id)}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <CreateWork
        visible={openArticle}
        onClose={onAddNewArticle}
        setLoading={setLoadingUpdate}
        id={idEdit}
        table_name={dataTable.TABLE_WORKING}
        table_type={dataTable.TABLE_WORKING}
      />
      <Confirm
        visible={openConfirm}
        onClose={onOpenConfirm}
        header={"Delete Article"}
        title={"Are you sure you want to delete article?"}
        onConfirm={onDelete}
      />
    </section>
  );
}

export default ListWorking;
