import React, { useState, useEffect } from "react";
import "./material-dashboard.css";
import NavBar from "./common/navbar";
import Header from "./common/header";
import * as dataTable from "../constant_table";
import Loading from "../common/loading";
import CreateArticle from "./common/create_article";
import { TYPE_ARTICLE } from "../constant";
import Confirm from "./common/confirm";
import { toast } from "react-toastify";
import API from "../../api";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const [dataHome, setDataHome] = useState([]);
  const [itemIdEdit, setItemIdEdit] = useState({});
  const [openArticle, setOpenArticle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [hiddenID, setHiddenID] = useState("");

  useEffect(() => {
    getPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPageView = async () => {
    await API.getAPIData(
      `/api/post_list/get_list.php?page_view=${dataTable.TABLE_HOME}`
    ).then((res) => {
      if (res.success) {
        setDataHome(res.data);
      } else {
        if (res.code === "re-login") {
          navigate("/admin/login");
        }
        toast.error(res.message);
      }

      setLoading(false);
    });
  };

  const onAddNewArticle = () => {
    getPageView();
    setOpenArticle(!openArticle);
    setItemIdEdit(0);
  };

  const onEditArticle = (item_id) => {
    setItemIdEdit(item_id);
    setOpenArticle(true);
  };

  const onOpenConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  const onConfirmHidden = async (id) => {
    setHiddenID(id);
    onOpenConfirm();
  };

  const onDelete = async () => {
    onOpenConfirm();
    setLoading(true);
    API.postParam("/api/post_list/change_status_post.php", {
      id: hiddenID,
    }).then((res) => {
      if (res.success) {
        getPageView();
        toast.success("Update Status Successfully");
      } else {
        if (res.code === "re-login") {
          navigate("/admin/login");
        }
        toast.error(res.message);
      }
    });
    setLoading(false);
  };

  return (
    <section className="dashboard-admin">
      {(loading || loadingUpdate) && <Loading />}
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Home" />
        <div className="container-fluid py-4">
          <div className="d-flex justify-content-end">
            <button
              style={{ border: "0px" }}
              className="justify-content-end badge badge-sm bg-gradient-primary p-2"
              onClick={() => onAddNewArticle()}
            >
              <span className="p-2">Add new</span>
            </button>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Type
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Title
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Status
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold text-center">
                            Index
                          </th>
                          <th
                            style={{ width: "70px" }}
                            className="text-secondary opacity-7"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataHome.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="align-middle">
                                <span>
                                  {
                                    TYPE_ARTICLE.find(
                                      (x) => x.value === item.type
                                    ).label
                                  }
                                </span>
                              </td>
                              <td className="align-middle">
                                <span>{item.title}</span>
                              </td>
                              <td className="align-middle">
                                <span>
                                  {item.status === "1" ? "Hidden" : "View"}
                                </span>
                              </td>
                              <td className="align-middle text-center ">
                                <span>{item.sort_index}</span>
                              </td>
                              <td className="align-middle justify-content-space-between">
                                <button
                                  className="btn-edit-list"
                                  onClick={() => onEditArticle(item.id)}
                                >
                                  Edit
                                </button>
                                {item.status === "1" ? (
                                  <button
                                    className="btn-delete-list"
                                    style={{ marginLeft: "2px" }}
                                    onClick={() => onConfirmHidden(item.id)}
                                  >
                                    Show
                                  </button>
                                ) : (
                                  <button
                                    className="btn-delete-list"
                                    style={{ marginLeft: "2px" }}
                                    onClick={() => onConfirmHidden(item.id)}
                                  >
                                    Hidden
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <CreateArticle
        visible={openArticle}
        onClose={onAddNewArticle}
        setLoading={setLoadingUpdate}
        id={itemIdEdit}
        table_name={dataTable.TABLE_HOME}
      />
      <Confirm
        visible={openConfirm}
        onClose={onOpenConfirm}
        header={"Show or Hide"}
        title={"Are you sure you want to change status article?"}
        onConfirm={onDelete}
      />
    </section>
  );
}

export default Home;
