import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";

function ArticleVideo({ videomp4 }) {
  const [processBar, setProcessBar] = useState(0);
  const [playVideo, setPlayVideo] = useState(true);
  const [soundVideo, setSoundVideo] = useState(false);

  const onClickPlay = () => {
    const isPlay = !playVideo;
    setPlayVideo(isPlay);
    if (isPlay) {
      document.getElementById("video_player").play();
    } else {
      document.getElementById("video_player").pause();
    }
  };

  useEffect(() => {
    setInterval(() => {
      const elm = document.getElementById("video_player");
      const dur = elm.duration;
      const currentTime = elm.currentTime || 0;
      setProcessBar(parseInt(currentTime) / parseInt(dur));
    }, 1000);
    return () => {
      clearInterval(setInterval);
    };
  }, [processBar]);

  const onClickSound = () => {
    const isSound = !soundVideo;
    setSoundVideo(isSound);
    document.getElementById("video_player").muted = !isSound;
  };
  const onClickFull = () => {
    document.getElementById("video_player").requestFullscreen();
  };

  return (
    <article
      id="post-6"
      className="entry-content post-6 page type-page status-publish hentry"
    >
      <article className="wp-block-luna-m01-hero m01 break-out">
        <figure className="m16__wrapper luna-video-player is-autoplay is-playing can-play">
          <video
            muted
            autoPlay={true}
            loop={true}
            playsInline
            className="m16__video"
            id="video_player"
          >
            <source type="video/mp4" src={videomp4} />
          </video>
          <figcaption className="m16__caption">
            <div className="video-progress">
              <span
                className="video-progress__value"
                style={{ transform: `scaleX(${processBar})` }}
              ></span>
            </div>
            <div className="video-controls">
              <button
                type="button"
                className="video-control__button"
                onClick={() => onClickPlay()}
              >
                {playVideo && <Icon.Pause />}
                {!playVideo && <Icon.Play />}
              </button>
              <button
                type="button"
                className="video-control__button "
                onClick={() => onClickSound()}
              >
                {soundVideo && <Icon.Volume2 />}
                {!soundVideo && <Icon.VolumeX />}
              </button>
              <button
                type="button"
                className="video-control__button "
                onClick={() => onClickFull()}
              >
                <Icon.Maximize />
              </button>
            </div>
          </figcaption>
        </figure>
      </article>
    </article>
  );
}

export default ArticleVideo;
