import React, { useState, useEffect } from "react";
import "./material-dashboard.css";
import NavBar from "./common/navbar";
import Header from "./common/header";
import { Col, Row } from "reactstrap";
import Loading from "../common/loading";
import Confirm from "./common/confirm";
import { toast } from "react-toastify";
import SelectImage from "./common/select_images";
import API from "../../api";

function Setting() {
  document.title = "Company Information";
  const [openComfirm, setOpenComfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [description, setDescription] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [logo, setLogo] = useState("");
  const [openSelectImage, setOpenSelectImage] = useState(false);
  const [fieldUpdate, setFieldUpdate] = useState("");

  useEffect(() => {
    getCompanyinfo();
  }, []);

  const getCompanyinfo = async () => {
    await API.getAPIData("/api/company/get_company_information.php").then(
      (res) => {
        if (res.success) {
          const infoData = res.data;
          setCompanyName(infoData.company_name);
          setDescription(infoData.description);
          setColor(infoData.color_web);
          setIcon(infoData.url_icon);
          setLogo(infoData.url_logo);
          setCompanyEmail(infoData.email);
          setCompanyPhone(infoData.company_phone);
        }
      }
    );
    setLoadingList(false);
  };

  const onChangePopup = async () => {
    setOpenComfirm(!openComfirm);
  };

  const onUpdate = async () => {
    onChangePopup();
    setLoading(true);
    API.postParam("/api/company/save_company_information.php", {
      company_name: companyName,
      company_phone: companyPhone,
      email: companyEmail,
      description: description,
      color_web: color,
      url_icon: icon,
      url_logo: logo,
    }).then((res) => {
      if (res.success) {
        toast.success("Upload successfully!");
      } else {
        toast.error("Upload false!");
      }
    });
    setLoading(false);
  };

  const onOpenSelectImage = (fieldName) => {
    setFieldUpdate(fieldName);
    setOpenSelectImage(!openSelectImage);
  };

  const onChangeImg = (value) => {
    if (fieldUpdate === "logo") {
      setLogo(value);
    } else if (fieldUpdate === "icon") {
      setIcon(value);
    }
    setOpenSelectImage(!openSelectImage);
  };

  return (
    <section className="dashboard-admin">
      {(loading || loadingList) && <Loading />}
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Company Information" />
        <div className="container-fluid py-4">
          <div className="d-flex justify-content-end">
            <button
              style={{ border: "0px" }}
              className="justify-content-end badge badge-sm bg-gradient-primary p-2"
              onClick={() => onChangePopup()}
            >
              <span className="p-2">Save</span>
            </button>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body px-0" style={{ margin: "20px" }}>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Company Name:</label>
                    </Col>
                    <Col xs={10}>
                      <input
                        type="text"
                        style={{ with: "98%", marginLeft: "15px" }}
                        className={"form-control"}
                        value={companyName}
                        maxLength="199"
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Company Phone:</label>
                    </Col>
                    <Col xs={10}>
                      <input
                        type="text"
                        style={{ with: "98%", marginLeft: "15px" }}
                        className={"form-control"}
                        value={companyPhone}
                        maxLength="15"
                        onChange={(e) => setCompanyPhone(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Company Email:</label>
                    </Col>
                    <Col xs={10}>
                      <input
                        type="text"
                        style={{ with: "98%", marginLeft: "15px" }}
                        className={"form-control"}
                        value={companyEmail}
                        maxLength="45"
                        onChange={(e) => setCompanyEmail(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Description Website:</label>
                    </Col>
                    <Col xs={10}>
                      <textarea
                        type="text"
                        style={{ with: "98%", marginLeft: "15px" }}
                        className={"form-control"}
                        value={description}
                        maxLength="999"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Color Website:</label>
                    </Col>
                    <Col xs={10} style={{ display: "flex" }}>
                      <input
                        type="text"
                        style={{ maxWidth: "200px", marginLeft: "15px" }}
                        className={"form-control"}
                        value={color}
                        maxLength="15"
                        onChange={(e) => setColor(e.target.value)}
                      />
                      <input
                        type="color"
                        style={{
                          width: "100px",
                          marginLeft: "15px",
                          alignSelf: "center",
                        }}
                        className={"form-control"}
                        value={color}
                        maxLength="100"
                        onChange={(e) => setColor(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Icon:</label>
                    </Col>
                    <Col xs={10} style={{ display: "flex" }}>
                      <input
                        type="text"
                        style={{
                          with: "98%",
                          marginLeft: "15px",
                          padding: "9px",
                        }}
                        className={"form-control"}
                        defaultValue={icon}
                        disabled
                      />
                      <button
                        className="btn-edit"
                        onClick={() => onOpenSelectImage("icon")}
                      >
                        Edit
                      </button>
                    </Col>
                  </Row>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Logo:</label>
                    </Col>
                    <Col xs={10} style={{ display: "flex" }}>
                      <input
                        type="text"
                        style={{
                          with: "98%",
                          marginLeft: "15px",
                          padding: "9px",
                        }}
                        className={"form-control"}
                        defaultValue={logo}
                        disabled
                      />
                      <button
                        className="btn-edit"
                        onClick={() => onOpenSelectImage("logo")}
                      >
                        Edit
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Confirm
          visible={openComfirm}
          onClose={onChangePopup}
          onConfirm={onUpdate}
          header={"Update setting"}
          title={"Are you sure you want to update infor?"}
        />
        <SelectImage
          visible={openSelectImage}
          onClose={onOpenSelectImage}
          onSave={onChangeImg}
        />
      </main>
    </section>
  );
}

export default Setting;
