import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";
import Confirm from "./confirm";
import {
  TYPE_DETAIL,
  CREATE_TYPE_VALID,
  CREATE_IMAGES_VALID,
  CREATE_LINK_VALID,
} from "../../constant";
import JoditEditor from "jodit-react";
import * as Icon from "react-feather";
import SelectImage from "./select_images";
import API from "../../../api";

export default function CreateWork({
  visible,
  onClose,
  setLoading,
  id = 0,
  table_name,
  table_type,
}) {
  const [openComfirm, setOpenComfirm] = useState(false);
  const [types, setTypes] = useState([]);
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [mediumImage, setMediumImage] = useState("");
  const [bntName, setBtnName] = useState("");
  const [linkTo, setLinkTo] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [indexUpdate, setIndexUpdate] = useState(-1);
  const [description, setDescription] = useState("");
  const [detail, setDetail] = useState([]);
  const [openSelectImage, setOpenSelectImage] = useState(false);
  // const [listKeyCode, setListKeyCode] = useState([]);
  const [selectedKey, setSelectedKey] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    getType();

    if (id > 0) {
      get_detail(id);
    } else {
      setTitle("");
      setDescription("");
      setMediumImage("");
      setBtnName("");
      setIsVisible(true);
      setLinkTo("");
      setDetail([]);
      setSelectedKey("");
      setShow(false);
    }
  }, [id]);

  const get_detail = async (item_id) => {
    await API.getAPIData(`/api/out_work/get_detail.php?id=${item_id}`).then(
      (res) => {
        if (res.success) {
          const itemDetail = res.data;
          setType(itemDetail.type);
          setTitle(itemDetail.title);
          setDescription(itemDetail.description);
          setMediumImage(itemDetail.url_image);
          setBtnName(itemDetail.button_name);
          setLinkTo(itemDetail.to_url);
          setIsVisible(itemDetail.is_activity === "1");
          setDetail(res.data_detail || []);
          setSelectedKey(itemDetail.code || "");
          setShow(itemDetail.show);
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  const getType = async () => {
    await API.getAPIData("/api/out_work/get_type_list.php").then((res) => {
      if (res.success) {
        setTypes(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  // const getType = async () => {
  //   const querySnapshot = await getDocs(dbColection);
  //   const queryData = querySnapshot.docs.map((doc) => ({
  //     ...doc.data(),
  //     id: doc.id,
  //   }));
  //   setTypes([...queryData]);

  //   const dbType = collection(db, dataTable.TABLE_KEY_CODE);
  //   const querySnapshotType = await getDocs(dbType);

  //   setListKeyCode(
  //     querySnapshotType.docs.map((doc) => ({
  //       ...doc.data(),
  //       id: doc.id,
  //     }))
  //   );
  // };

  const onCreateHome = async () => {
    if (!validateValue()) return;

    const updFields = {
      id: id,
      type: type || "",
      description: description,
      title: title || "",
      url_image: mediumImage || "",
      button_name: bntName || "",
      is_visible: isVisible,
      to_url: linkTo || "",
      is_activity: show || true,
      detail: JSON.stringify(detail),
      page_view: table_name,
      code: selectedKey | "",
    };
    setLoading(true);
    API.postParam("/api/out_work/insert_or_update_detail.php", updFields).then(
      (res) => {
        if (res.success) {
          toast.success("Update successfully!");
          setOpenComfirm(false);
          onClose();
        } else {
          toast.error(res.message);
        }
      }
    );
    setLoading(false);
  };

  const validateValue = () => {
    if (
      CREATE_TYPE_VALID.includes(table_type) &&
      (type === "" || type === null || type === undefined)
    ) {
      toast.error("Type is required!");
      setOpenComfirm(false);
      return false;
    }
    // if (detail.length < 1) {
    //   toast.error("Details must not be empty!");
    //   setOpenComfirm(false);
    //   return false;
    // }

    if (
      CREATE_TYPE_VALID.includes(table_type) &&
      (selectedKey === "" || selectedKey === null || selectedKey === undefined)
    ) {
      toast.error("Key is required!");
      setOpenComfirm(false);
      return false;
    }

    return true;
  };

  const onOpenSelectImage = (index) => {
    setIndexUpdate(index);
    setOpenSelectImage(!openSelectImage);
  };

  const onChangeImage = (value) => {
    if (indexUpdate >= 0) {
      const editDetail = detail;
      editDetail[indexUpdate].url = value;
      setDetail([...editDetail]);
    } else if (indexUpdate === "Medium") {
      setMediumImage(value);
    }
    onOpenSelectImage(-1);
  };

  const onChangeOpemConform = () => {
    setOpenComfirm(!openComfirm);
  };

  const onAddDetail = () => {
    const newDetail = [
      ...detail,
      { title: "", content: "", url: "", type: "normal", sub_title: "" },
    ];
    setDetail(newDetail);
  };

  const onDeletedDetail = (index) => {
    const list = [...detail];

    list.splice(index, 1);
    setDetail([...list]);
  };

  const setContentDetail = (value, index) => {
    const editDetail = detail;
    editDetail[index].description = value;
    setDetail([...editDetail]);
  };

  const setImageDetail = (value, index) => {
    const editDetail = detail;
    editDetail[index].url_image = value;
    setDetail([...editDetail]);
  };

  const setTypeDetail = (value, index) => {
    const editDetail = detail;
    editDetail[index].type = value;
    setDetail([...editDetail]);
  };

  const onChangeTitle = (value, index) => {
    const editDetail = detail;
    editDetail[index].title = value;
    setDetail([...editDetail]);
  };

  const onChangeSubTitle = (value, index) => {
    const editDetail = detail;
    editDetail[index].sub_title = value;
    setDetail([...editDetail]);
  };

  const config = {
    readonly: false,
    controls: {
      font: {
        list: {
          "system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji":
            "For web",
        },
      },
      fontsize: {
        list: [
          "8",
          "9",
          "10",
          "11",
          "12",
          "14",
          "16",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "30",
          "36",
          "48",
          "60",
          "72",
          "96",
        ],
      },
    },
  };

  const listKeyCode = [
    {
      id: 1,
      lable: "All Project",
    },
    {
      id: 1,
      lable: "All Service",
    },
  ];

  return (
    <>
      <Modal
        size="lg"
        isOpen={visible}
        style={{ zIndex: "100", marginTop: "0px", marginBottom: "0px" }}
      >
        <ModalHeader>{id ? "Edit article" : "Create article"}</ModalHeader>
        <ModalBody>
          {CREATE_TYPE_VALID.includes(table_type) && (
            <Row className="p-2">
              <Col xs={2}>
                <label>Type working:</label>
              </Col>
              <Col xs={10}>
                <select
                  className="form-control"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="">--- Type ---</option>
                  {types.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </Col>
            </Row>
          )}
          {table_name !== "our_work" && (
            <Row className="p-2">
              <Col xs={2}>
                <label>Key:</label>
              </Col>
              <Col xs={10}>
                <select
                  className="form-control"
                  value={selectedKey}
                  onChange={(e) => setSelectedKey(e.target.value)}
                >
                  <option value="0">--- Key ---</option>
                  {listKeyCode.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.lable}
                      </option>
                    );
                  })}
                </select>
              </Col>
            </Row>
          )}
          <Row className="p-2">
            <Col xs={2}>
              <label>Title:</label>
            </Col>
            <Col xs={10}>
              <input
                type="text"
                className={"form-control"}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                maxLength={100}
              />
            </Col>
          </Row>
          <Row className="p-2">
            <Col xs={2}>
              <label>Description:</label>
            </Col>
            <Col xs={10}>
              <JoditEditor
                className="ml-15px"
                config={config}
                value={description}
                tabIndex={200}
                onBlur={(e) => setDescription(e)}
              />
            </Col>
          </Row>
          {(CREATE_IMAGES_VALID.includes(table_type) ||
            table_name === "our_work") && (
            <Row className="p-2">
              <Col xs={2}>
                <label>Medium Image:</label>
              </Col>
              <Col xs={10} style={{ display: "flex" }}>
                <input
                  type="text"
                  className={"form-control"}
                  value={mediumImage}
                  onChange={(e) => setMediumImage(e.target.value)}
                  disabled
                />
                <div style={{ width: "50px", height: "35px" }}>
                  <img src={"/api/image/" + mediumImage} alt={"img"} />
                </div>
                <button
                  className="btn-edit"
                  onClick={() => {
                    onOpenSelectImage("Medium");
                  }}
                >
                  Edit
                </button>
              </Col>
            </Row>
          )}
          {CREATE_LINK_VALID.includes(table_type) && (
            <Row className="p-2">
              <Col xs={2}>
                <label>Button name:</label>
              </Col>
              <Col xs={10}>
                <input
                  type="text"
                  className={"form-control"}
                  value={bntName}
                  onChange={(e) => setBtnName(e.target.value)}
                  maxLength={50}
                />
              </Col>
            </Row>
          )}
          {(CREATE_LINK_VALID.includes(table_type) ||
            table_name === "our_work") && (
            <Row className="p-2">
              <Col xs={2}>
                <label>Link:</label>
              </Col>
              <Col xs={10}>
                <input
                  type="text"
                  className={"form-control"}
                  value={linkTo}
                  onChange={(e) => setLinkTo(e.target.value)}
                />
              </Col>
            </Row>
          )}

          <Row className="p-2">
            <Col xs={2}>
              <label>Active:</label>
            </Col>
            <Col xs={10}>
              <input
                type="checkbox"
                style={{ width: "20px", height: "20px" }}
                checked={isVisible}
                onChange={() => setIsVisible(!isVisible)}
              />
            </Col>
          </Row>
          <Row className="p-2">
            <Col xs={2}>
              <label>Details:</label>
            </Col>
            <Col xs={10}>
              {detail.map((item, index) => {
                return (
                  <div key={index}>
                    <Row className="p-2">
                      <Col xs={2}>
                        <label>Title:</label>
                      </Col>
                      <Col xs={10}>
                        <input
                          className={"form-control"}
                          value={item.title}
                          onChange={(e) => onChangeTitle(e.target.value, index)}
                          maxLength={100}
                        />
                      </Col>
                    </Row>
                    <Row className="p-2">
                      <Col xs={2}>
                        <label>Sub title:</label>
                      </Col>
                      <Col xs={10}>
                        <input
                          className={"form-control"}
                          value={item.sub_title}
                          onChange={(e) =>
                            onChangeSubTitle(e.target.value, index)
                          }
                          maxLength={200}
                        />
                      </Col>
                    </Row>
                    <Row className="p-2">
                      <Col xs={2}>
                        <label>Content:</label>
                      </Col>
                      <Col xs={10}>
                        <JoditEditor
                          className="ml-15px"
                          value={item.description}
                          tabIndex={200}
                          onBlur={(e) => setContentDetail(e, index)}
                        />
                      </Col>
                    </Row>
                    <Row className="p-2">
                      <Col xs={2}>
                        <label>Image:</label>
                      </Col>
                      <Col xs={10} style={{ display: "flex" }}>
                        <input
                          type="text"
                          className={"form-control"}
                          value={item.url_image}
                          onChange={(e) =>
                            setImageDetail(e.target.value, index)
                          }
                          disabled
                        />
                        <div style={{ width: "50px", height: "35px" }}>
                          <img src={"/api/image/" + item.url} alt={"img"} />
                        </div>
                        <button
                          className="btn-edit"
                          onClick={() => {
                            onOpenSelectImage(index);
                          }}
                        >
                          Edit
                        </button>
                      </Col>
                    </Row>
                    <Row className="p-2">
                      <Col xs={2}>
                        <label>Type:</label>
                      </Col>
                      <Col xs={10} style={{ display: "flex" }}>
                        <select
                          className="form-control"
                          value={item.type}
                          onChange={(e) => setTypeDetail(e.target.value, index)}
                        >
                          {TYPE_DETAIL.map((type, tIndex) => {
                            return (
                              <option key={tIndex} value={type.value}>
                                {type.label}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn-delete-list"
                        onClick={() => onDeletedDetail(index)}
                      >
                        <Icon.Minus />
                      </button>
                    </div>
                  </div>
                );
              })}
              <button className="btn-edit-list" onClick={() => onAddDetail()}>
                <Icon.Plus />
              </button>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-small btn-light"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </button>
            <Button
              className="bg-gradient-primary"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={() => setOpenComfirm(true)}
            >
              Confirm
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Confirm
        visible={openComfirm}
        onClose={onChangeOpemConform}
        onConfirm={onCreateHome}
        header={id > 0 ? "Update work" : "Create work"}
        title={
          id > 0
            ? "Are you sure you want to update work?"
            : "Are you sure you want to create work?"
        }
      />
      <SelectImage
        visible={openSelectImage}
        onClose={onOpenSelectImage}
        onSave={onChangeImage}
      />
    </>
  );
}
