import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import API from "../../api";
import { Col, Row } from "reactstrap";
import logioBTC from "../../assets/images/logobtc.png";

function Footer() {
  const [offset, setOffset] = useState(0);
  const [polices, setPolices] = useState([]);
  const [company, setCompany] = useState({});

  useEffect(() => {
    if (polices.length === 0) {
      getListPolices();
      getCompanyinfo();
    }
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getListPolices = async () => {
    await API.getAPIData(`/api/company/get_polices.php`).then((res) => {
      if (res.success) {
        setPolices(res.data);
      }
    });
  };

  const getCompanyinfo = async () => {
    await API.getAPIData("/api/company/get_company_information.php").then(
      (res) => {
        if (res.success) {
          setCompany(res.data);
        }
      }
    );
  };

  const onTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  return (
    <footer className="g03" style={{ backgroundColor: company.color_web }}>
      <div className="container container--large">
        <Row>
          <Col>
            <a
              href="/"
              className="g03__logo"
              aria-label="Select to return to the top of the page"
            >
              {company?.url_logo && (
                <img
                  src={"/api/image/" + company?.url_logo}
                  alt={"/api/image/" + company?.url_logo}
                  width="150"
                  className="g03__logo-image"
                />
              )}
            </a>
            <a href="http://online.gov.vn/Home/WebDetails/108728">
              <img
                className="logo-bct"
                alt="Anh BTC"
                title="Anh BTC"
                src={logioBTC}
              />
            </a>
          </Col>
          <div className="col-lg-4 col-md-12 col-sm-12">
            {polices.length > 0 && (
              <div className="footer-police g03__copyright">
                <div className="title">Chính sách và điều khoản</div>
                <ul>
                  {polices.map((value, index) => {
                    return (
                      <li key={index}>
                        <a href={`/police/detail/${value.id}`}>{value.title}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
          <Col className="col-lg-5 col-md-12 col-sm-12">
            <div className="g03__copyright">
              <div style={{ fontSize: "1.3rem" }}>{company.company_name}</div>
              <div style={{ display: "flex" }}>
                <Icon.MapPin size={18} />
                <span style={{ marginLeft: "10px" }}>
                  {company.company_address}
                </span>
              </div>
              <div>
                <a
                  href={"mailto:" + company.email}
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    display: "flex",
                  }}
                >
                  <Icon.Mail size={18} />
                  <span style={{ marginLeft: "10px" }}>{company.email}</span>
                </a>
              </div>
              <div className="d-flex">
                <Icon.Phone size={18} />{" "}
                <span style={{ marginLeft: "10px" }}>
                  {company.company_phone}
                </span>
              </div>
              <div>{company.description}</div>
            </div>
          </Col>
        </Row>
      </div>
      {offset > 200 && (
        <button className="btn-top" title="Go to top" onClick={() => onTop()}>
          <div>
            <Icon.Triangle className="arrow-up-btn-top" size={7} />
          </div>
          <div className="btn-top-label">TOP</div>
        </button>
      )}
    </footer>
  );
}

export default Footer;
