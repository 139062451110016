import React, { useEffect, useState } from "react";
import Header from "./common/header";
import ArticleMedia from "./common/article_media";
import ArticleAnimationRight from "./common/article_animation_right";
import OurWork from "./common/our_work";
import ArticleAnimationLeft from "./common/article_animation_left";
import Footer from "./common/footer";
import ListWorking from "./common/list_working";
import ArticleVideo from "./common/article_video";
import ArticleLogo from "./common/article_logo";
import * as dataTable from "./constant_table";
import { TYPE_ARTICLE } from "./constant";
import ArticleService from "./common/article_service";
import SubmitForm from "./common/submit_form";
import ArticleTitle from "./common/article_title";
import ArticleVacancies from "./common/article_vacancies";
import API from "../api";

function Home() {
  const [dataHome, setDataHome] = useState([]);
  const [data, setData] = useState("");
  const [logo, setLogo] = useState("");

  useEffect(() => {
    getCompanyinfo();
    getPageView();
  }, []);

  const getCompanyinfo = async () => {
    API.getAPIData("/api/company/get_company_information.php").then((res) => {
      if (res.success) {
        const data = res.data;

        setData(data);
        setLogo(data.url_logo);
      }
    });
  };

  const getPageView = async () => {
    await API.getAPIData(
      `/api/post_list/get_post_list.php?page_view=${dataTable.TABLE_HOME}`
    ).then((res) => {
      if (res.success) {
        setDataHome(res.data);
      }
    });
  };

  return (
    <section>
      <Header backgroundColor={data.color_web} imgLogo={logo} data={data} />
      {dataHome.map((item, index) => {
        if (item.type === TYPE_ARTICLE[0].value) {
          return (
            <ArticleMedia
              key={index}
              sub_title={item.sub_title}
              title={item.title}
              content={item.description}
              nameButon={item.button_name}
              imageUrl={item.url_image}
            />
          );
        } else if (item.type === TYPE_ARTICLE[1].value) {
          return <ArticleAnimationRight key={index} item={item} />;
        } else if (item.type === TYPE_ARTICLE[2].value) {
          return <ArticleAnimationLeft key={index} item={item} />;
        } else if (item.type === TYPE_ARTICLE[3].value) {
          return (
            <ArticleService
              title={item.title}
              key={index}
              keyCode={item.key_code}
            />
          );
        } else if (item.type === TYPE_ARTICLE[4].value) {
          return <ArticleLogo key={index} />;
        } else if (item.type === TYPE_ARTICLE[5].value) {
          return <ArticleVideo key={index} videomp4={item.url_image} />;
        } else if (item.type === TYPE_ARTICLE[6].value) {
          return (
            <ArticleTitle
              title={item.title}
              content={item.sub_title}
              key={index}
            />
          );
        } else if (item.type === TYPE_ARTICLE[7].value) {
          return (
            <SubmitForm
              key={index}
              backgroundColor={data.color_web}
              title={item.title}
              subTitle={item.sub_title}
            />
          );
        } else if (item.type === TYPE_ARTICLE[8].value) {
          return (
            <OurWork key={index} data={item} backgroundColor={data.color_web} />
          );
        } else if (item.type === TYPE_ARTICLE[9].value) {
          return (
            <ListWorking
              backgroundColor={data.color_web}
              key={index}
              data={item}
            />
          );
        } else if (item.type === TYPE_ARTICLE[10].value) {
          return <ArticleVacancies data={item} key={index} />;
        }
        return <></>;
      })}

      <Footer />
    </section>
  );
}

export default Home;
