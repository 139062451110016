import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import API from "../../api";

function ArticleService({ title, keyCode }) {
  // const [dataAll, setDataAll] = useState([]);
  // const [datas, setDatas] = useState([]);
  // const [listHeader, setListHeader] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState("");
  const [listItem, setListItem] = useState([]);
  const [translate3d, setTranslate3] = useState(0);
  const [curItem, setCurItem] = useState(0);
  const [typeWork, setTypeWork] = useState([]);

  const styleTransform = {
    transform: `translate3d(-${translate3d}px, 0px, 0px)`,
    transition: `transform  .9s ease`,
  };
  useEffect(() => {
    getInfo();
    getList("all");
  }, []);
  const getInfo = async () => {
    await API.getAPIData("/api/out_work/get_type_list.php").then((res) => {
      if (res.success) {
        setTypeWork(res.data);
      }
    });
  };

  const getList = async (type) => {
    await API.getAPIData(
      `/api/out_work/get_working_list_type.php?type=${type}`
    ).then((res) => {
      if (res.success) {
        setListItem(res.data);
      }
    });

    setSelectedHeader(type);
  };

  const repareListItem = (fieldHerder) => {
    setTranslate3(0);
    setSelectedHeader(fieldHerder);
    getList(fieldHerder);
  };

  const onClickPrevious = () => {
    const divId = document.getElementById(`services_m10__filters_${curItem}`);

    let plCurItem = curItem - 1;
    if (curItem === 0) {
      plCurItem = 0;
      setCurItem(0);
    } else {
      setCurItem(plCurItem);
    }
    if (divId) {
      const withId = divId.offsetWidth;
      const plusNum = plCurItem === 0 ? 0 : translate3d - withId - 21;

      setTranslate3(plusNum);
    }
  };

  const onClickNext = () => {
    const divId = document.getElementById(`services_m10__filters_${curItem}`);
    const itemLength = listItem.length;
    let plCurItem = curItem + 1;
    if (curItem >= itemLength - 1) {
      plCurItem = 0;
      setCurItem(0);
    } else {
      setCurItem(plCurItem);
    }
    if (divId) {
      const withId = divId.offsetWidth;

      const plusNum = plCurItem === 0 ? 0 : translate3d + withId + 21;

      setTranslate3(plusNum);
    }
  };

  return (
    <article className="m10 break-out">
      <div className="m10__header container">
        <h1 className="m10__heading uppercase">{title}</h1>
      </div>

      <div className="m10__filters container init-magic-line magic-line-mode-line">
        <div className="magic-line-inner">
          <div className="m10__select-wrapper">
            <select
              name="filter-service-type"
              data-filter="filter-service-type"
              className="m10__select terra__select terra-filter  default-style"
              onChange={(e) => repareListItem(e.target.value)}
              value={selectedHeader}
            >
              <option value="all">Show all</option>
              {typeWork.map((value, index) => {
                return (
                  <option value={value.code} key={index}>
                    {value.name}
                  </option>
                );
              })}
            </select>
          </div>
          <label
            className={
              "all" === selectedHeader
                ? "m10__filter__label terra__radio__label terra_radio_label terra_radio_label_active"
                : "m10__filter__label terra__radio__label terra_radio_label"
            }
            onClick={() => repareListItem("all")}
          >
            SHOW ALL
          </label>
          {typeWork.map((value, index) => {
            return (
              <label
                className={
                  value === selectedHeader
                    ? "m10__filter__label terra__radio__label terra_radio_label terra_radio_label_active"
                    : "m10__filter__label terra__radio__label terra_radio_label"
                }
                key={index}
                onClick={() => repareListItem(value.code)}
              >
                {value.name}
              </label>
            );
          })}
        </div>
      </div>

      <div className="terra-container m10__items is-draggable class_servies">
        <div className="m10__container" style={styleTransform}>
          {listItem.map((item, index) => {
            return (
              <article
                className="single-service-item m10__slide is-selected"
                key={index}
                id={`services_m10__filters_${index}`}
              >
                <a
                  href={`/detail/${item.id}`}
                  className="single-service-item__link"
                >
                  <figure className="single-service-item__media">
                    <picture>
                      <img
                        src={"/api/image" + item.url_image}
                        alt=""
                        width="375"
                        height="211"
                        className="single-service-item__image"
                      />
                    </picture>
                  </figure>

                  <div className="single-service-item__content">
                    <h3 className="single-service-item__heading heading--4 uppercase">
                      {item.title}
                    </h3>

                    <div className="single-service-item__excerpt">
                      <span>{parse(item.description)}</span>
                      <strong className="read-more">{item.button_name}</strong>
                    </div>
                  </div>
                </a>
              </article>
            );
          })}
        </div>
      </div>
      <input type="hidden" name="posts-offset" value="26" />

      <div className="m10__button-wrap">
        <button
          type="button"
          className="button m10__button m10__button--prev"
          aria-label="Select to go to the previous service"
          disabled={curItem === 0}
          onClick={() => onClickPrevious()}
        >
          <svg
            aria-hidden="true"
            className="svg-icon svg-icon--icon_arrow"
            role="img"
          >
            <use href="#sprite-icon_arrow"></use>
          </svg>
        </button>
        <button
          type="button"
          className="button m10__button m10__button--next"
          aria-label="Select to go to the next service"
          onClick={() => onClickNext()}
          // disabled={curItem === listItem.length}
        >
          <svg
            aria-hidden="true"
            className="svg-icon svg-icon--icon_arrow"
            role="img"
          >
            <use href="#sprite-icon_arrow"></use>
          </svg>
        </button>
      </div>
    </article>
  );
}

export default ArticleService;
