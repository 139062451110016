import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";
import {
  TYPE_ARTICLE,
  DISABLE_BUTTON_NAME,
  DISABLE_DESCRIPTION,
  DISABLE_SUBTITLE,
  DISABLE_URL,
  DISABLE_KEY_CODE,
} from "../../constant";
import SelectImage from "./select_images";
import SelectVideo from "./select_video";
import Confirm from "./confirm";
import JoditEditor from "jodit-react";
import * as Icon from "react-feather";
import API from "../../../api";

toast.configure();

export default function CreateArticle({
  visible,
  onClose,
  setLoading,
  id = 0,
  table_name,
  data = {},
}) {
  const [openImages, setOpenImages] = useState(false);
  const [openMp4, setOpenMp4] = useState(false);
  const [openComfirm, setOpenComfirm] = useState(false);
  const [type, setType] = useState(TYPE_ARTICLE[0].value);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [bntName, setBtnName] = useState("");
  const [linkTo, setLinkTo] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [indexView, setIndexView] = useState("");
  const [keyCode, setKeyCode] = useState("");

  useEffect(() => {
    if (id > 0) {
      get_detail(id);
    } else {
      setType("article");
      setTitle("");
      setDescription("");
      setUrl("");
      setBtnName("");
      setSubTitle("");
      setIndexView("");
      setLinkTo("");
      setKeyCode("");
    }
  }, [id]);

  const get_detail = (item_id) => {
    API.getAPIData(`/api/post_list/get_detail.php?id=${item_id}`).then(
      (res) => {
        if (res.success) {
          const itemDetail = res.data;
          setType(itemDetail.type);
          setTitle(itemDetail.title);
          setDescription(itemDetail.description);
          setUrl(itemDetail.url_image);
          setBtnName(itemDetail.button_name);
          setSubTitle(itemDetail.sub_title);
          setIndexView(itemDetail.sort_index);
          setLinkTo(itemDetail.to_url);
          // setKeyCode(itemDetail.);
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  const onCreateHome = async () => {
    if (!validateValue()) return;

    setLoading(true);
    const updateFields = {
      id: parseInt(id),
      type: type,
      title: title,
      sub_title: subTitle || "",
      description: description || "",
      url_image: url || "",
      button_name: bntName || "",
      to_url: linkTo || "",
      sort_index: indexView || 0,
      page_view: table_name,
    };

    API.postParam("/api/post_list/insert_or_update_detail.php", updateFields)
      .then((res) => {
        if (res.success) {
          toast.success("Update successfully!");
          setOpenMp4(false);
          setOpenComfirm(false);
          onClose();
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });

    setLoading(false);
  };

  const validateValue = () => {
    if (indexView === "" || indexView === null || indexView === undefined) {
      toast.error("Index is required!");
      setOpenComfirm(false);
      return false;
    }
    return true;
  };

  const onChangeSelect = () => {
    setOpenImages(!openImages);
  };

  const onOpenSelectImage = () => {
    setOpenImages(!openImages);
  };

  const onChangeSelectImage = (value) => {
    if (value !== "" && value !== null) {
      setUrl(value);
    }
    onOpenSelectImage();
  };

  const onOpenSelectVideo = () => {
    setOpenMp4(!openMp4);
  };

  const onChangeSelectVideo = (value) => {
    if (value !== "" && value !== null) {
      setUrl(value);
    }
    onOpenSelectVideo();
  };

  const onChangeOpemConform = () => {
    setOpenComfirm(!openComfirm);
  };

  const autoKeyCode = () => {
    const now = new Date();
    const mnth = ("0" + (now.getMonth() + 1)).slice(-2);
    const day = ("0" + now.getDate()).slice(-2);
    const keyTime =
      now.getFullYear() +
      mnth +
      day +
      now.getHours() +
      now.getMinutes() +
      now.getSeconds();
    const keyName = title ? title.replace(" ", "_") : "SV";
    setKeyCode(keyName.toLowerCase() + "_" + keyTime);
  };

  const config = {
    readonly: false,
    controls: {
      font: {
        list: {
          "system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji":
            "For web",
        },
      },
      fontsize: {
        list: [
          "8",
          "9",
          "10",
          "11",
          "12",
          "14",
          "16",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "30",
          "36",
          "48",
          "60",
          "72",
          "96",
        ],
      },
    },
  };

  return (
    <>
      <Modal size="lg" isOpen={visible} style={{ zIndex: "100" }}>
        <ModalHeader>{id ? "Edit article" : "Create article"}</ModalHeader>
        <ModalBody>
          <Row className="p-2">
            <Col xs={2}>
              <label>Type:</label>
            </Col>
            <Col xs={10}>
              <select
                className="form-control"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {TYPE_ARTICLE.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>

          <Row className="p-2">
            <Col xs={2}>
              <label>Title:</label>
            </Col>
            <Col xs={10}>
              <input
                type="text"
                className={"form-control"}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                maxLength={100}
              />
            </Col>
          </Row>
          {!DISABLE_KEY_CODE.includes(type) && (
            <Row className="p-2">
              <Col xs={2}>
                <label>Key:</label>
              </Col>
              <Col xs={10} style={{ display: "flex" }}>
                <input
                  type="text"
                  className={"form-control"}
                  value={keyCode}
                  onChange={(e) => e}
                  disabled={id > 0}
                />
                {id === 0 && (
                  <button
                    className="btn-edit"
                    onClick={() => {
                      autoKeyCode();
                    }}
                  >
                    <Icon.RefreshCcw />
                  </button>
                )}
              </Col>
            </Row>
          )}
          {!DISABLE_SUBTITLE.includes(type) && (
            <Row className="p-2">
              <Col xs={2}>
                <label>Sub Title:</label>
              </Col>
              <Col xs={10}>
                <input
                  type="text"
                  className={"form-control"}
                  value={subTitle}
                  onChange={(e) => setSubTitle(e.target.value)}
                  maxLength={200}
                />
              </Col>
            </Row>
          )}
          {!DISABLE_DESCRIPTION.includes(type) && (
            <Row className="p-2">
              <Col xs={2}>
                <label>Description:</label>
              </Col>
              <Col xs={10}>
                <JoditEditor
                  value={description}
                  config={config}
                  tabIndex={200}
                  onBlur={(e) => setDescription(e)}
                />
              </Col>
            </Row>
          )}
          {!DISABLE_URL.includes(type) && (
            <Row className="p-2">
              <Col xs={2}>
                <label> {type === "video" ? "Url Video" : "Url Image"}:</label>
              </Col>
              <Col xs={10} style={{ display: "flex" }}>
                <input
                  type="text"
                  className={"form-control"}
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  disabled
                />
                {type !== "video" && (
                  <div style={{ width: "50px", height: "35px" }}>
                    <img src={"/api/image/" + url} alt={"img"} />
                  </div>
                )}
                <button
                  className="btn-edit"
                  onClick={() => {
                    onChangeSelect();
                  }}
                >
                  Edit
                </button>
              </Col>
            </Row>
          )}
          {!DISABLE_BUTTON_NAME.includes(type) && (
            <Row className="p-2">
              <Col xs={2}>
                <label>Button name:</label>
              </Col>
              <Col xs={10}>
                <input
                  type="text"
                  className={"form-control"}
                  value={bntName}
                  onChange={(e) => setBtnName(e.target.value)}
                  maxLength={50}
                />
              </Col>
            </Row>
          )}
          {!DISABLE_BUTTON_NAME.includes(type) && (
            <Row className="p-2">
              <Col xs={2}>
                <label>Link:</label>
              </Col>
              <Col xs={10}>
                <input
                  type="text"
                  className={"form-control"}
                  value={linkTo}
                  onChange={(e) => setLinkTo(e.target.value)}
                />
              </Col>
            </Row>
          )}
          <Row className="p-2">
            <Col xs={2}>
              <label>Index:</label>
            </Col>
            <Col xs={10}>
              <input
                type="text"
                className={"form-control"}
                value={indexView}
                onChange={(e) => setIndexView(e.target.value)}
                onKeyPress={(event) => {
                  if (!/[0-9.]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={2}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-small btn-light"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </button>
            <Button
              className="bg-gradient-primary"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={() => setOpenComfirm(true)}
            >
              Confirm
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Confirm
        visible={openComfirm}
        onClose={onChangeOpemConform}
        onConfirm={onCreateHome}
        header={id ? "Update article" : "Create article"}
        title={
          id
            ? "Are you sure you want to update article?"
            : "Are you sure you want to create article?"
        }
      />
      <SelectImage
        visible={openImages}
        onClose={onOpenSelectImage}
        onSave={onChangeSelectImage}
      />
      <SelectVideo
        visible={openMp4}
        onClose={onOpenSelectVideo}
        onSave={onChangeSelectVideo}
        videoLocation={url}
      />
    </>
  );
}

// import React, { useState, useEffect } from "react";
// import {
//   Modal,
//   ModalBody,
//   ModalFooter,
//   ModalHeader,
//   Button,
//   Row,
//   Col,
// } from "reactstrap";
// import { toast } from "react-toastify";
// import {
//   TYPE_ARTICLE,
//   DISABLE_BUTTON_NAME,
//   DISABLE_DESCRIPTION,
//   DISABLE_SUBTITLE,
//   DISABLE_URL,
//   DISABLE_KEY_CODE,
// } from "../../constant";
// import { collection, addDoc, doc, updateDoc } from "@firebase/firestore";
// import { db } from "../../../firebase-config";
// import SelectImage from "./select_images";
// import SelectVideo from "./select_video";
// import Confirm from "./confirm";
// import JoditEditor from "jodit-react";
// import { TABLE_KEY_CODE } from "../../constant_table";
// import * as Icon from "react-feather";
// import API from "../../../api";

// toast.configure();

// export default function CreateArticle({
//   visible,
//   onClose,
//   setLoading,
//   data = {},
//   table_name,
// }) {
//   const [openImages, setOpenImages] = useState(false);
//   const [openMp4, setOpenMp4] = useState(false);
//   const [openComfirm, setOpenComfirm] = useState(false);
//   const [type, setType] = useState(TYPE_ARTICLE[0].value);
//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [url, setUrl] = useState("");
//   const [bntName, setBtnName] = useState("");
//   const [linkTo, setLinkTo] = useState("");
//   const [subTitle, setSubTitle] = useState("");
//   const [indexView, setIndexView] = useState("");
//   const [keyCode, setKeyCode] = useState("");

//   useEffect(() => {
//     if (Object.keys(data).length > 0) {
//       setType(data.type);
//       setTitle(data.title);
//       setDescription(data.description);
//       setUrl(data.url);
//       setBtnName(data.button_name);
//       setSubTitle(data.sub_title);
//       setIndexView(data.index);
//       setLinkTo(data.link);
//       setKeyCode(data.key_code);
//     } else {
//       setType(TYPE_ARTICLE[0].value);
//       setTitle("");
//       setDescription();
//       setUrl("");
//       setBtnName("");
//       setSubTitle("");
//       setIndexView("");
//       setLinkTo("");
//       setKeyCode("");
//     }
//   }, [data]);

//   const onCreateHome = async () => {
//     if (!validateValue()) return;

//     // setLoading(true);
//     const updateFields = {
//       id: parseInt(0),
//       type: type,
//       title: title,
//       sub_title: subTitle || "",
//       description: description || "",
//       url_image: url || "",
//       button_name: bntName || "",
//       to_url: linkTo || "",
//       sort_index: indexView || 0,
//       page_view: table_name,
//     };

//     API.postParam("/api/post_list/insert_or_update_detail.php", updateFields)
//       .then((res) => {
//         if (res.success) {
//           toast.success("Update successfully!");
//           setOpenMp4(false);
//           setOpenComfirm(false);
//           onClose();
//         } else {
//           toast.error(res.message);
//         }
//       })
//       .catch((error) => {
//         toast.error(error);
//       });

//     // if (Object.keys(data).length > 0) {
//     //   const updDoc = doc(db, table_name, data.id);
//     //   const updFields = repareFields();

//     //   await updateDoc(updDoc, updFields)
//     //     .then(() => {
//     //       toast.success("Update successfully!");
//     //       setOpenMp4(false);
//     //       setOpenComfirm(false);
//     //       onClose();
//     //     })
//     //     .catch((error) => {
//     //       toast.error(error);
//     //     })
//     //     .finally(() => {
//     //       setLoading(false);
//     //     });
//     // } else {
//     //   const newFields = repareFields();
//     //   const usersColection = collection(db, table_name);
//     //   await addDoc(usersColection, newFields)
//     //     .then(() => {
//     //       toast.success("Create user successfully!");
//     //       setOpenMp4(false);
//     //       setOpenComfirm(false);
//     //       onClose();
//     //     })
//     //     .catch((error) => {
//     //       toast.error(error);
//     //     })
//     //     .finally(() => {
//     //       setLoading(false);
//     //     });

//     //   if (!!keyCode) {
//     //     const usersColection = collection(db, TABLE_KEY_CODE);
//     //     await addDoc(usersColection, { key_code: keyCode, title: title });
//     //   }
//     // }
//   };

//   const validateValue = () => {
//     if (indexView === "" || indexView === null || indexView === undefined) {
//       toast.error("Index is required!");
//       setOpenComfirm(false);
//       return false;
//     }
//     return true;
//   };

//   const onChangeSelect = () => {
//     setOpenImages(!openImages);
//   };

//   const repareFields = () => {
//     if (type === "video") {
//       return {
//         type: type,
//         title: title,
//         url: url || "",
//         index: indexView,
//       };
//     } else if (type === "list_logo" || type === "list-working") {
//       return {
//         type: type,
//         title: title,
//         index: indexView,
//       };
//     } else if (type === "title" || type === "vacancies") {
//       return {
//         type: type,
//         title: title,
//         index: indexView,
//         sub_title: subTitle || "",
//       };
//     } else if (type === "our-work") {
//       return {
//         type: type,
//         title: title,
//         index: indexView,
//         link: linkTo || "",
//         button_name: bntName || "",
//       };
//     } else if (type === "all_services") {
//       return {
//         type: type,
//         title: title,
//         index: indexView,
//         key_code: keyCode,
//       };
//     } else {
//       return {
//         description: description,
//         type: type,
//         title: title,
//         url: url || "",
//         button_name: bntName || "",
//         sub_title: subTitle || "",
//         index: indexView,
//         link: linkTo || "",
//       };
//     }
//   };

//   const onOpenSelectImage = () => {
//     setOpenImages(!openImages);
//   };

//   const onChangeSelectImage = (value) => {
//     if (value !== "" && value !== null) {
//       setUrl(value);
//     }
//     onOpenSelectImage();
//   };

//   const onOpenSelectVideo = () => {
//     setOpenMp4(!openMp4);
//   };

//   const onChangeSelectVideo = (value) => {
//     if (value !== "" && value !== null) {
//       setUrl(value);
//     }
//     onOpenSelectVideo();
//   };

//   const onChangeOpemConform = () => {
//     setOpenComfirm(!openComfirm);
//   };

//   const autoKeyCode = () => {
//     const now = new Date();
//     const mnth = ("0" + (now.getMonth() + 1)).slice(-2);
//     const day = ("0" + now.getDate()).slice(-2);
//     const keyTime =
//       now.getFullYear() +
//       mnth +
//       day +
//       now.getHours() +
//       now.getMinutes() +
//       now.getSeconds();
//     const keyName = title ? title.replace(" ", "_") : "SV";
//     setKeyCode(keyName.toLowerCase() + "_" + keyTime);
//   };

//   const config = {
//     readonly: false,
//     controls: {
//       font: {
//         list: {
//           "system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji":
//             "For web",
//         },
//       },
//       fontsize: {
//         list: [
//           "8",
//           "9",
//           "10",
//           "11",
//           "12",
//           "14",
//           "16",
//           "18",
//           "19",
//           "20",
//           "21",
//           "22",
//           "23",
//           "24",
//           "30",
//           "36",
//           "48",
//           "60",
//           "72",
//           "96",
//         ],
//       },
//     },
//   };

//   return (
//     <>
//       <Modal size="lg" isOpen={visible} style={{ zIndex: "100" }}>
//         <ModalHeader>{data.id ? "Edit article" : "Create article"}</ModalHeader>
//         <ModalBody>
//           <Row className="p-2">
//             <Col xs={2}>
//               <label>Type:</label>
//             </Col>
//             <Col xs={10}>
//               <select
//                 className="form-control"
//                 value={type}
//                 onChange={(e) => setType(e.target.value)}
//               >
//                 {TYPE_ARTICLE.map((item, index) => {
//                   return (
//                     <option key={index} value={item.value}>
//                       {item.label}
//                     </option>
//                   );
//                 })}
//               </select>
//             </Col>
//           </Row>

//           <Row className="p-2">
//             <Col xs={2}>
//               <label>Title:</label>
//             </Col>
//             <Col xs={10}>
//               <input
//                 type="text"
//                 className={"form-control"}
//                 value={title}
//                 onChange={(e) => setTitle(e.target.value)}
//               />
//             </Col>
//           </Row>
//           {!DISABLE_KEY_CODE.includes(type) && (
//             <Row className="p-2">
//               <Col xs={2}>
//                 <label>Key:</label>
//               </Col>
//               <Col xs={10} style={{ display: "flex" }}>
//                 <input
//                   type="text"
//                   className={"form-control"}
//                   value={keyCode}
//                   onChange={(e) => e}
//                   disabled={Object.keys(data).length > 0}
//                 />
//                 {Object.keys(data).length < 1 && (
//                   <button
//                     className="btn-edit"
//                     onClick={() => {
//                       autoKeyCode();
//                     }}
//                   >
//                     <Icon.RefreshCcw />
//                   </button>
//                 )}
//               </Col>
//             </Row>
//           )}
//           {!DISABLE_SUBTITLE.includes(type) && (
//             <Row className="p-2">
//               <Col xs={2}>
//                 <label>Sub Title:</label>
//               </Col>
//               <Col xs={10}>
//                 <input
//                   type="text"
//                   className={"form-control"}
//                   value={subTitle}
//                   onChange={(e) => setSubTitle(e.target.value)}
//                 />
//               </Col>
//             </Row>
//           )}
//           {!DISABLE_DESCRIPTION.includes(type) && (
//             <Row className="p-2">
//               <Col xs={2}>
//                 <label>Description:</label>
//               </Col>
//               <Col xs={10}>
//                 <JoditEditor
//                   value={description}
//                   config={config}
//                   tabIndex={200}
//                   onBlur={(e) => setDescription(e)}
//                 />
//               </Col>
//             </Row>
//           )}
//           {!DISABLE_URL.includes(type) && (
//             <Row className="p-2">
//               <Col xs={2}>
//                 <label> {type === "video" ? "Url Video" : "Url Image"}:</label>
//               </Col>
//               <Col xs={10} style={{ display: "flex" }}>
//                 <input
//                   type="text"
//                   className={"form-control"}
//                   value={url}
//                   onChange={(e) => setUrl(e.target.value)}
//                   disabled
//                 />
//                 {type !== "video" && (
//                   <div style={{ width: "50px", height: "35px" }}>
//                     <img src={"/api/image/" + url} alt={"img"} />
//                   </div>
//                 )}
//                 <button
//                   className="btn-edit"
//                   onClick={() => {
//                     onChangeSelect();
//                   }}
//                 >
//                   Edit
//                 </button>
//               </Col>
//             </Row>
//           )}
//           {!DISABLE_BUTTON_NAME.includes(type) && (
//             <Row className="p-2">
//               <Col xs={2}>
//                 <label>Button name:</label>
//               </Col>
//               <Col xs={10}>
//                 <input
//                   type="text"
//                   className={"form-control"}
//                   value={bntName}
//                   onChange={(e) => setBtnName(e.target.value)}
//                 />
//               </Col>
//             </Row>
//           )}
//           {!DISABLE_BUTTON_NAME.includes(type) && (
//             <Row className="p-2">
//               <Col xs={2}>
//                 <label>Link:</label>
//               </Col>
//               <Col xs={10}>
//                 <input
//                   type="text"
//                   className={"form-control"}
//                   value={linkTo}
//                   onChange={(e) => setLinkTo(e.target.value)}
//                 />
//               </Col>
//             </Row>
//           )}
//           <Row className="p-2">
//             <Col xs={2}>
//               <label>Index:</label>
//             </Col>
//             <Col xs={10}>
//               <input
//                 type="text"
//                 className={"form-control"}
//                 value={indexView}
//                 onChange={(e) => setIndexView(e.target.value)}
//               />
//             </Col>
//           </Row>
//         </ModalBody>
//         <ModalFooter>
//           <div className="d-flex justify-content-end">
//             <button
//               className="btn btn-small btn-light"
//               onClick={() => {
//                 onClose();
//               }}
//             >
//               Cancel
//             </button>
//             <Button
//               className="bg-gradient-primary"
//               color="primary"
//               style={{ marginLeft: "10px" }}
//               onClick={() => setOpenComfirm(true)}
//             >
//               Confirm
//             </Button>
//           </div>
//         </ModalFooter>
//       </Modal>
//       <Confirm
//         visible={openComfirm}
//         onClose={onChangeOpemConform}
//         onConfirm={onCreateHome}
//         header={data.id ? "Update article" : "Create article"}
//         title={
//           data.id
//             ? "Are you sure you want to update article?"
//             : "Are you sure you want to create article?"
//         }
//       />
//       <SelectImage
//         visible={openImages}
//         onClose={onOpenSelectImage}
//         onSave={onChangeSelectImage}
//       />
//       <SelectVideo
//         visible={openMp4}
//         onClose={onOpenSelectVideo}
//         onSave={onChangeSelectVideo}
//         videoLocation={url}
//       />
//     </>
//   );
// }
