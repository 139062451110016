const API = {
  getAPIData: (url) => {
    return getUrl(url);
  },
  postParam: (url, param) => {
    return postUrlParam(url, param);
  },
  postParamArray: (url, param) => {
    const data = JSON.stringify(param);
    return postUrlParam(url, { data: data.toString() });
  },
};

export async function postUrlParam(url, param) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: new URLSearchParams(param),
    };

    const response = await fetch(url, requestOptions).then((response) =>
      response.json()
    );

    return response;
  } catch (err) {
    return { success: false, message: err.message };
  }
}

export function getUrl(url) {
  try {
    return fetch(url).then((response) => response.json());
  } catch (err) {
    return { success: false, message: err.message };
  }
}

export default API;
