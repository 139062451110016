import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import API from "../../api";

function Header({
  backgroundColor,
  imgLogo,
  data = {},
  showAddToCart = false,
  loading = false,
}) {
  const [offset, setOffset] = useState(0);
  const pathname = window.location.pathname;
  const [quantityCart, setQuantityCart] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (data) {
      document.getElementsByTagName("META")["title"].content =
        data.company_name;
      document.getElementsByTagName("META")["image"].content = data.url_logo;
      document.getElementsByTagName("LINK")["icon"].href = data.url_icon;
      document.getElementsByTagName("LINK")["canonical"].href =
        data.description;
      document.getElementsByTagName("META")["description"].content =
        data.description;
    }
  }, [data]);

  const onShowMenuMobile = () => {
    const menu = document.getElementById("menu-wrapper-mb");
    const containsMenuMb = menu.classList.contains("menu-mb");
    if (containsMenuMb) {
      menu.classList.remove("menu-mb");
    } else {
      menu.classList.add("menu-mb");
    }
  };

  const getToCart = () => {
    API.getAPIData("/api/order/get_to_cart.php").then((res) => {
      if (res.success) {
        const listCart = res.data || [];
        const value = listCart.reduce((acc, cur) => {
          return acc + parseInt(cur.quantity);
        }, 0);

        setQuantityCart(value);
      }
    });
  };

  useEffect(() => {
    getToCart();
  }, [showAddToCart, loading]);

  return (
    <section>
      <header
        className="g01 is-scrolled"
        role="banner"
        style={
          offset > 50
            ? {
                position: "fixed",
                maxHeight: "4rem",
                backgroundColor: backgroundColor,
              }
            : { backgroundColor: backgroundColor }
        }
      >
        <a href="/" className="g01__logo">
          {imgLogo && (
            <img
              src={"/api/image/" + imgLogo}
              alt={imgLogo}
              className="g01__logo-image"
            />
          )}
        </a>

        <button
          type="button"
          className="g01__hamburger"
          onClick={() => onShowMenuMobile()}
        >
          <span className="screen-reader-text">Toggle main menu</span>
        </button>

        <div
          className="g01__menu-wrapper"
          id="menu-wrapper-mb"
          style={{
            // backgroundColor: "#ff0037 !important",
            backgroundColor: backgroundColor,
          }}
        >
          <nav className="g01__primary">
            <ul id="primary-menu" className="g01__primary-menu">
              <li
                className={
                  pathname === "/"
                    ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item menu-item-home"
                    : "menu-item menu-item-type-post_type menu-item-object-page menu-item-home"
                }
              >
                <a title="home" href="/">
                  Home
                </a>
              </li>
              <li
                className={
                  pathname === "/services"
                    ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item"
                    : "menu-item menu-item-type-post_type menu-item-object-page "
                }
              >
                <a title="services" href="/services">
                  {/* Services */}
                  Lĩnh vực kinh doanh
                </a>
              </li>
              <li
                className={
                  pathname === "/projects"
                    ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item"
                    : "menu-item menu-item-type-post_type menu-item-object-page "
                }
              >
                <a title="projects" href="/projects">
                  {/* Projects */}
                  Dự án nổi bật
                </a>
              </li>
              <li
                className={
                  pathname === "/culture_careers"
                    ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item"
                    : "menu-item menu-item-type-post_type menu-item-object-page "
                }
              >
                <a title="culture careers" href="/culture_careers">
                  {/* Culture & Careers */}
                  Văn hoá và cơ hội nghề nghiệp
                </a>
              </li>
              <li
                className={
                  pathname === "/product"
                    ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item"
                    : "menu-item menu-item-type-post_type menu-item-object-page "
                }
              >
                <a title="culture careers" href="/product">
                  {/* Product */}
                  Sản phẩm cung cấp
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <a className="icon-shoping" href="/cart">
          {!!showAddToCart && (
            <div className="txt-add-to-cart" id="show-add-to-cart">
              Add to cart
            </div>
          )}

          <span className="shoping-item">{quantityCart}</span>
          <Icon.ShoppingCart size="18" />
        </a>
      </header>
    </section>
  );
}

export default Header;
