import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import API from "../../api";

function ArticleVacancies({ data = {} }) {
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    getPageView();
  }, []);

  const getPageView = async () => {
    await API.getAPIData("/api/out_work/get_vacancies_list.php").then((res) => {
      if (res.success) {
        setDatas(res.data);
      }
    });
  };

  return (
    <article className="m18 break-out" id="vacancies">
      <div className="container m18__container">
        <h2 className="m18__heading heading--1 uppercase">{data.title}</h2>

        <div className="m18__introduction">
          <p>{data.sub_title}</p>
        </div>

        <div className="m18__vacancies">
          {datas.map((item, index) => {
            return (
              <div className="single-vacancy-item" key={index}>
                <a
                  href={`/detail/${item.id}`}
                  className="single-vacancy-item__link"
                  target=""
                >
                  <div className="single-vacancy-item__content">
                    <h3 className="single-vacancy-item__heading heading--4 uppercase">
                      {item.title}
                    </h3>
                    <div className="single-vacancy-item__description">
                      {parse(item.description)}
                      <span className="read-more">Read more...</span>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </article>
  );
}

export default ArticleVacancies;
