import React, { useEffect, useState } from "react";
import Header from "./common/header";
import Footer from "./common/footer";
import SubmitForm from "./common/submit_form";
import API from "../api";
import parse from "html-react-parser";
import { toast } from "react-toastify";
import { numberWithComma } from "./helper";
import PopupProductReview from "./common/popup_product_review";
import ReviewStar from "./common/review_star";
import { Button } from "reactstrap";

function Product() {
  const [products, setProducts] = useState([]);
  const [data, setData] = useState("");
  const [logo, setLogo] = useState("");
  const [loadToCart, setLoadToCart] = useState(false);
  const [selectStar, setSelectStar] = useState(0);
  const [openReview, setOpenReview] = useState(false);
  const [productReview, setProductReview] = useState([]);
  const [offsetReview, setOffsetReview] = useState(0);
  const [enableNext, setEnableNext] = useState(false);
  // document.title = "";
  useEffect(() => {
    getCompanyinfo();
    getProducts();
    getProductReview(offsetReview);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCompanyinfo = async () => {
    API.getAPIData("/api/company/get_company_information.php").then((res) => {
      if (res.success) {
        const data = res.data;

        setData(data);
        setLogo(data.url_logo);
      }
    });
  };

  const getProducts = async () => {
    await API.getAPIData("/api/product/get_products.php").then((res) => {
      if (res.success) {
        setProducts([...res.data]);
      }
    });
  };

  const getProductReview = async (offset) => {
    await API.getAPIData(
      `/api/product/get_product_review.php?offset=${offset}`
    ).then((res) => {
      if (res.success) {
        const data = res.data;

        if (data.length < 10) {
          setEnableNext(true);
        }

        if (offset === 0) {
          setOffsetReview(11);
          setProductReview(data);
        } else {
          setProductReview([...productReview, ...data]);
          setOffsetReview(offset + 10);
        }
      }
    });
  };

  const onAddToCart = async (item) => {
    await API.postParam("/api/order/add_to_cart.php", item).then((res) => {
      if (res.success) {
        setLoadToCart(true);
      } else {
        toast.error(res.message);
      }
    });
    setTimeout(() => {
      setLoadToCart(false);
    }, 1000);
  };

  const onReadMore = (classId) => {
    document.getElementById(`read-more-${classId}`).className = "";
    document.getElementById(`read-more-bnt-${classId}`).className = "none";
  };

  const onClickStar = (numStar) => {
    setSelectStar(numStar);
    setOpenReview(true);
  };

  const onMouseHover = (numStar) => {
    setSelectStar(numStar);
  };

  const onClosePopup = () => {
    setOpenReview(false);
  };

  const onCompletedReview = () => {
    toast.success("Cảm ơn bạn đã gửi đánh giá!");
    setOpenReview(false);
    getProductReview(0);
  };

  const onNextReview = () => {
    getProductReview(offsetReview);
  };

  return (
    <section>
      <Header
        backgroundColor={data.color_web}
        imgLogo={logo}
        data={data}
        showAddToCart={loadToCart}
      />

      <div className="product-list-item mt-2 mb-2">
        {products?.map((value, index) => {
          return (
            <div className="product-items" key={`product-${index}`}>
              <div className="product-wrap">
                <div>
                  <img src={"/api/image/" + value.url_image} alt="img" />
                </div>
                <div className="product-item-content">
                  <p className="heading--6 text-center product-name">
                    {value.product_name}
                  </p>
                  <p className="text-4-line" id={`read-more-${value.id}`}>
                    {parse(value.description)}
                  </p>
                  <p
                    className="read-more"
                    id={`read-more-bnt-${value.id}`}
                    onClick={() => onReadMore(value.id)}
                  >
                    Xem thêm...
                  </p>

                  <p>
                    <span className="text-price">
                      {numberWithComma(value.price)}
                    </span>
                  </p>

                  <div className="text-center">
                    <button
                      className="product-item-buton"
                      onClick={() => onAddToCart(value)}
                    >
                      THÊM VÀO GIỎ HÀNG
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="p-2 mt-3">
          <div className="heading--6">ĐÁNH GIÁ SẢN PHẦM</div>
          <div className="product-review">
            <div className="heading--7">Đánh giá sản phẩm</div>
            <div>
              Nếu bạn đã sữ dụng sản phầm tại SMART. Hãy đánh giá ngay để giúp
              hàng ngàn người chọn lựa dịch vụ tốt nhất bạn nhé!
            </div>
            <ReviewStar
              star={selectStar}
              onClickStar={onClickStar}
              onMouseHover={onMouseHover}
              addkey="products"
            />
          </div>
        </div>
        <div className="comment-list">
          {productReview.map((review, index) => {
            return (
              <div className="customer-comment" key={`review-${index}`}>
                <div className="customer-name">{review.customer_name}</div>
                <div className="comment">{review.comment}</div>
              </div>
            );
          })}
          <div className="d-flex justify-content-center w-100 mt-3">
            <Button
              className="btn-next-review"
              color="primary"
              onClick={() => onNextReview()}
              disabled={enableNext}
            >
              Xem thêm ...
            </Button>
          </div>
        </div>
      </div>
      <SubmitForm backgroundColor={data.color_web} title="REACH US" />
      <PopupProductReview
        visible={openReview}
        onClose={onClosePopup}
        star={selectStar}
        products={products}
        onConfirm={onCompletedReview}
      />
      <Footer backgroundColor={data.color} imgLogo={logo} data={data} />
    </section>
  );
}

export default Product;
