import React from "react";
import { Modal, Button } from "reactstrap";
import * as Icon from "react-feather";

export default function PopupNotify(props) {
  const { visible = false, onClose, notify, onSuccess } = props;

  const onClosePopup = () => {
    if (notify.isSuccess) {
      onSuccess();
    } else {
      onClose();
    }
  };

  return (
    <Modal
      size="md"
      isOpen={visible}
      className="popup-product-review"
      style={{ top: "10%" }}
    >
      <div className="position-absolute p-4 mb-2 bg-light text-dark fixed-top m-3 rounded">
        <div className="text-center p-2">
          <h5>THÔNG BÁO</h5>
          <div className="x-lose">
            <Icon.X onClick={() => onClosePopup()} />
          </div>
        </div>
        <div className="p-2">{notify?.message}</div>
        <div className="d-flex justify-content-center w-100 mt-3">
          {notify.isSuccess ? (
            <Button
              className="btn-sent-review"
              color="primary"
              onClick={() => onClosePopup()}
            >
              Xác nhận
            </Button>
          ) : (
            <Button
              className="btn-sent-review"
              color="danger"
              onClick={() => onClosePopup()}
            >
              Đóng
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
