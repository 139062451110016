import React, { useState, useEffect } from "react";
import "./material-dashboard.css";
import NavBar from "./common/navbar";
import Header from "./common/header";
import * as dataTable from "../constant_table";
import Loading from "../common/loading";
import { toast } from "react-toastify";
import Confirm from "./common/confirm";
import CreateWork from "./common/create_type_work";
import { useNavigate } from "react-router-dom";
import API from "../../api";

function TypeWorking() {
  const navigate = useNavigate();
  const [dataHome, setDataHome] = useState([]);
  const [dataEdit, setdataEdit] = useState({});
  const [openArticle, setOpenArticle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [idDelete, setIdDelete] = useState("");

  const getPageView = async () => {
    await API.getAPIData("/api/out_work/get_type_list.php").then((res) => {
      if (res.success) {
        setDataHome(res.data);
      } else {
        if (res.code === "re-login") {
          navigate("/admin/login");
        }
        toast.error(res.message);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    getPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddNewArticle = () => {
    setOpenArticle(!openArticle);
    setdataEdit({});
    getPageView();
  };

  const onEditArticle = (valueEdit) => {
    setdataEdit(valueEdit);
    setOpenArticle(true);
  };

  const onOpenConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  const onConfirmDeleted = async (itemId) => {
    setIdDelete(itemId);
    onOpenConfirm();
  };

  const onDelete = async () => {
    onOpenConfirm();
    setLoading(true);
    API.getAPIData(`/api/out_work/delete_type.php?id=${idDelete}`).then(
      (res) => {
        if (res.success) {
          toast.success("Deleted successfully!");
          getPageView();
        } else {
          toast.error(res.message);
        }
      }
    );
    setLoading(false);
  };

  return (
    <section className="dashboard-admin">
      {(loading || loadingUpdate) && <Loading />}
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Home" />
        <div className="container-fluid py-4">
          <div className="d-flex justify-content-end">
            <button
              style={{ border: "0px" }}
              className="justify-content-end badge badge-sm bg-gradient-primary p-2"
              onClick={() => onAddNewArticle()}
            >
              <span className="p-2">Add new</span>
            </button>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-xxs font-weight-bold"></th>

                          <th className="text-uppercase text-xxs font-weight-bold">
                            Code
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Name
                          </th>
                          <th
                            style={{ width: "70px" }}
                            className="text-secondary opacity-7"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataHome.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ width: "20px" }}>
                                <div className="d-flex px-2 py-1">
                                  <div className="d-flex flex-column justify-content-center">
                                    <div className="mb-0 text-sm">
                                      {index + 1}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="align-middle">
                                <span>{item.code}</span>
                              </td>
                              <td className="align-middle">
                                <span>{item.name}</span>
                              </td>
                              <td className="align-middle justify-content-space-between">
                                <button
                                  className="btn-edit-list"
                                  onClick={() => onEditArticle(item)}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn-delete-list"
                                  style={{ marginLeft: "2px" }}
                                  onClick={() => onConfirmDeleted(item.id)}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <CreateWork
        visible={openArticle}
        onClose={onAddNewArticle}
        setLoading={setLoadingUpdate}
        data={dataEdit}
        table_name={dataTable.TABLE_TYPE_WORKKING}
      />
      <Confirm
        visible={openConfirm}
        onClose={onOpenConfirm}
        header={"Delete Type"}
        title={"Are you sure you want to delete type?"}
        onConfirm={onDelete}
      />
    </section>
  );
}

export default TypeWorking;
