export const DEFAULTE_ROLE = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "hr",
    label: "HR",
  },
  {
    value: "marketing",
    label: "Marketing",
  },
];

export const TYPE_ARTICLE = [
  {
    value: "article",
    label: "Article",
  },
  {
    value: "article_animation_right",
    label: "Article Animation Right",
  },
  {
    value: "article_animation_left",
    label: "Article Animation Left",
  },
  {
    value: "all_services",
    label: "All Services",
  },
  {
    value: "list_logo",
    label: "List Logo",
  },
  {
    value: "video",
    label: "Video",
  },
  {
    value: "title",
    label: "Title",
  },
  {
    value: "form-contact",
    label: "Form Contact",
  },
  {
    value: "our-work",
    label: "Our Work",
  },
  {
    value: "list-working",
    label: "List Working",
  },
  {
    value: "vacancies",
    label: "List Vacancies",
  },
];

export const DISABLE_BUTTON_NAME = [
  "video",
  "list_logo",
  "title",
  "form-contact",
  "list-working",
  "all_services",
  "vacancies",
];
export const DISABLE_DESCRIPTION = [
  "video",
  "list_logo",
  "title",
  "form-contact",
  "list-working",
  "our-work",
  "all_services",
  "vacancies",
];
export const DISABLE_SUBTITLE = [
  "video",
  "list_logo",
  "list-working",
  "our-work",
  "all_services",
];
export const DISABLE_URL = [
  "list_logo",
  "title",
  "form-contact",
  "list-working",
  "our-work",
  "all_services",
  "vacancies",
];

export const DISABLE_KEY_CODE = [
  "video",
  "list_logo",
  "list-working",
  "our-work",
  "vacancies",
  "article",
  "article_animation_right",
  "article_animation_left",
  "title",
  "form-contact",
];

export const ROLE_NAV_ADM_HR = ["admin", "hr"];

export const ROLE_NAV_ADM_MAR = ["admin", "marketing"];

export const TYPE_DETAIL = [
  {
    value: "normal",
    label: "Normal",
  },
  {
    value: "article_animation_right",
    label: "Animation Right",
  },
  {
    value: "article_animation_left",
    label: "Animation Left",
  },
];

export const TABLE_TYPE_VACANCIES = "list_vacancies";
export const TABLE_TYPE_WORKKING = "list_working";

export const CREATE_TYPE_VALID = ["list_working"];
export const CREATE_IMAGES_VALID = ["list_working"];
export const CREATE_LINK_VALID = ["list_working"];
