import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import API from "../../api";

function ArticleLogo() {
  const [datas, setDatas] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    getCompanyinfo();
  }, []);

  const getCompanyinfo = async () => {
    API.getAPIData("/api/post_list/get_list_logo.php").then((res) => {
      if (res.success) {
        const data = res.data;
        const stringUrl = data.description;
        const listUrl = stringUrl.split(",") || [];
        setTitle(data.title);
        setDatas(listUrl);
      }
    });
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <article className="wp-block-luna-m03-logo-slider m03 break-out">
        <div className="container">
          <h3 className="m03__label uppercase heading--6">{title}</h3>
        </div>
        <Carousel
          autoPlay={true}
          responsive={responsive}
          infinite={true}
          arrows={false}
          draggable={true}
          className="m-2"
        >
          {datas.map((item, iIndex) => {
            return (
              <div
                key={iIndex}
                className="m03__slide"
                style={{ minWidth: "300px", left: "0%" }}
              >
                <figure className="m03__media">
                  <img
                    draggable="false"
                    src={"/api/image" + item}
                    alt="/"
                    width="300"
                    height="169"
                    className="m03__media__image"
                  />
                </figure>
              </div>
            );
          })}
        </Carousel>
      </article>
    </>
  );
}

export default ArticleLogo;
