import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import * as Icon from "react-feather";
import ReviewStar from "./review_star";
import { toast } from "react-toastify";
import API from "../../api";

export default function PopupProductReview(props) {
  const { visible, onClose, onConfirm, star, products } = props;
  const [selectStar, setSelectStar] = useState();
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [selectProduct, setSelectProduct] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setSelectStar(star);
  }, [star]);

  const onClickStar = (numStar) => {
    setSelectStar(numStar);
  };

  const validateSubmit = () => {
    setError("");
    if (selectProduct === "" || selectProduct === undefined) {
      setError("option");
      toast.error("Bạn chưa chọn sản phẩm đánh giá!");
      return;
    }
    if (comment === "" || comment === undefined) {
      setError("comment");
      toast.error("Bạn chưa nhập nội dung cảm nhận!");
      return;
    }
    if (name === "" || name === undefined) {
      setError("name");
      toast.error("Bạn chưa nhận Họ Tên!");
      return;
    }
    if (phone === "" || phone === undefined) {
      setError("phone");
      toast.error("Bạn chưa nhập số điện thoại!");
      return;
    }

    const params = {
      product_id: selectProduct,
      customerName: name,
      customerPhone: phone,
      comment: comment,
      star: star,
    };
    API.postParam("/api/product/add_review.php", params).then((res) => {
      if (res.success) {
        onConfirm();
      } else {
        toast.error(
          "Hệ thông bảo trì vui lòng liên hệ SMART qua hotline 028 3920 8480 để được hỗ trợ!"
        );
      }
    });
  };

  return (
    <Modal size="lg" isOpen={visible} className="popup-product-review">
      <div className="popup-header">
        <div className="title">Đánh gián sản phẩm</div>
        <div className="x-lose">
          <Icon.X onClick={() => onClose()} />
        </div>
      </div>
      <ModalBody>
        <ReviewStar
          star={selectStar}
          onClickStar={onClickStar}
          onMouseHover={onClickStar}
          addkey="popup-review"
        />
        <div className="justify-content-center text-center">
          <select
            className={`option-review ${
              error === "option" ? "border-red" : ""
            }`}
            value={selectProduct}
            onChange={(e) => setSelectProduct(e.target.value)}
          >
            <option>---- Chọn sản phẩm bạn muốn đánh giá ----</option>
            {(products || []).map((product) => {
              return (
                <option
                  value={product.id}
                  key={`${product.id}-popup-option-review`}
                >
                  {product.product_name}
                </option>
              );
            })}
          </select>
        </div>

        <textarea
          className={`text-comment ${error === "comment" ? "border-red" : ""}`}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Mời bạn chia sẻ cảm nhận...."
          maxLength={1000}
        />
        <div className="cover-input">
          <input
            className={`input-name ${error === "name" ? "border-red" : ""}`}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Họ tên (Bắt buộc)"
            maxLength={50}
          />

          <input
            className={`input-phone ${error === "phone" ? "border-red" : ""}`}
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Số điện thoại (Bắt buộc)"
            maxLength={10}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-center w-100">
          <Button
            className="btn-sent-review"
            color="primary"
            onClick={() => validateSubmit()}
          >
            Gửi đánh giá
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
